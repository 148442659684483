import styled from '@emotion/styled';

import { getLangText } from 'utils/ui';

const Main = styled.div`
  line-height: 1em;

  &.color_brand {
    color: var(--brand);
  }

  &.color_muted {
    color: var(--bs-gray);
    font-size: 0.875em;
    font-weight: 300;
  }

  &.color_red {
    color: var(--bs-danger);
    font-weight: 600;
  }

  &.color_yellow {
    color: var(--bs-warning);
    font-weight: 600;
  }

  &.color_green {
    color: var(--bs-success);
    font-weight: 600;
  }
`;


export default function TextAnnotation({
  annotation,
}) {
  return (
    <Main className={`color_${annotation.color}`}>
      {annotation.icon && <i className={`fa far ${annotation.icon}`} />}
      <span>{getLangText(annotation.text)}</span>
    </Main>
  );
}
