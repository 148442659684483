import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import * as actions from 'actions';

import Loader from 'components/loader';
import UnitTypeCard from 'components/site-detail/unit-type-card';


export default function UnitTypeRowPreview({ previewMode }) {
  const { site: siteCode, type: unitTypeCode } = useParams();

  const { data: site } = useQuery('site', () => actions.sites.get(siteCode));
  const { data: unitType } = useQuery('unitType', () => actions.unitTypes.get(siteCode, unitTypeCode));

  if (!site || !unitType) return (
    <Loader />
  );

  return (
    <div className="p-3">
      <UnitTypeCard
        site={site}
        unitType={unitType}
        previewMode
      />
    </div>
  );
}
