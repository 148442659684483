import styled from '@emotion/styled';
import wurd, { WurdMarkdown } from 'wurd-react';
import Markdown from 'components/markdown';


const Container = styled.h1({
  ':is(h1)': {
    margin: '2rem auto',
    '@media (max-width: 992px)': {
      textAlign: 'center',
    },
  },
  'em': {
    color: 'var(--brand)',
    fontStyle: 'normal',
  }
});


export default function Title({
  cms,
  as = 'h1',
  vars,
  ...rest
}) {
  if (wurd.editMode) return <WurdMarkdown type={Container} id={cms.id()} vars={vars} {...rest} />;

  return <Markdown as={Container.withComponent(as)} inline {...rest}>{cms.text('', vars)}</Markdown>;
}

