import styled from '@emotion/styled';


const Container = styled.div({
  '@media (min-width: 992px)': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '1.5rem',
  }
})

export default function Split({
  className,
  children,
}) {
  return (
    <Container className={`container gx-0 mt-lg-2 ${className || ''}`}>
      {children}
    </Container>
  );
}
