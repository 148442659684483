import styled from '@emotion/styled';
import { getLangText } from 'utils/ui';

import ListItemCard from 'components/list-item-card';


const Container = styled.div({
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },
  'input[type="number"]': {
    MozAppearance: 'textfield',
    width: '1.7em',
    backgroundColor: 'transparent',
    border: 0,
    lineHeight: '1em',
  },
});

/**
 *
 * @param {Object} props
 * @param {Object} props.item
 * @param {Number} props.item.maxQuantity
 * @param {String} props.item.image
 * @param {String} props.item.imageFit
 * @param {String} props.item.code
 * @param {Object} props.item.title
 * @param {Object} props.item.displayPrice
 * @param {Object} props.item.info
 * @returns
 */
export default function Counter({ item, onChange, as, soldOutText = 'Sold out', ...props }) {
  const handleChange = e => onChange({ target: { name: e.target.name, value: Math.max(Math.min(+e.target.value, item.maxQuantity || Infinity), 0) } });
  const plus = () => handleChange({ target: { name: props.name, value: +props.value + 1 } });
  const minus = () => handleChange({ target: { name: props.name, value: +props.value - 1 } });

  return (
    <ListItemCard image={item.image} imageFit={item.imageFit} as={as}>
      <Container className="h-100 d-flex flex-column">
        <h3 className="text-truncate">{getLangText(item.title)}</h3>
        <div className="text-muted flex-1">{getLangText(item.info)}</div>

        <div className="mt-4 d-flex align-items-center justify-content-between">
          <div>
            <span className="fs-4 fw-600 ls-1">{getLangText(item.displayPrice)}</span>
          </div>

          <div className="btn-group" role="group" aria-label="Counter buttons">
            {item.maxQuantity <= 0
              ? <span className="text-muted fs-5 me-4">{soldOutText}</span>
              : (
                <>
                  <button type="button" onClick={minus} className="btn btn-default p-0">
                    <i className="fal fa-fw fa-minus fs-3" />
                  </button>
                  <input
                    type="number"
                    min="0"
                    max={item.maxQuantity}
                    id={`item-${item.code}`}
                    onChange={handleChange}
                    className="text-center fs-2 fw-light"
                    {...props}
                  />
                  <button type="button" onClick={plus} className="btn btn-default p-0">
                    <i className="fal fa-fw fa-plus fs-3" />
                  </button>
                </>
              )}
          </div>
        </div>
      </Container>
    </ListItemCard>
  );
}
