import { getLangText } from './ui';
import store from 'store';


export function getMapLink(site) {
  return `https://www.google.com/maps/place/${getLangText(site.address).replace(/\s/g, '+')}/@${site.lat},${site.lng},15z`;
}

export function typePrice(type) {
  return type.price_dynamic ?? (type.price_display || type.price);
}

/* export function getUnitTypes(site) {
  if (!site) return [];

  // Sort by price
  return site.unitTypes
    .sort((a, b) =>
      ((a.order || 0) - (b.order || 0)) ||
      (typePrice(a) - typePrice(b)) ||
      ((a.deposit_display || a.deposit) - (b.deposit_display || b.deposit))
    );
} */
