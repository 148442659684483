import styled from '@emotion/styled';

import Ribbon from './ribbon';
import Text from './text';

const annotationComponents = {
  ribbon: Ribbon,
  text: Text,
};

const Main = styled.div`
  position: relative;
`;


export default function Annotations({
  position,
  annotations = []
}) {
  const selectedAnnotations = annotations.filter(annotation => annotation.position === position);
  if (!selectedAnnotations.length) return null;

  return (
    <Main>
      {selectedAnnotations.map((annotation) => {
        const Annotation = annotationComponents[annotation.type];

        return (
          <Annotation key={annotation.id} annotation={annotation} />
        );
      })}
    </Main>
  );
}
