import { useMutation } from 'react-query';
import wurd from 'wurd-react';
import store from 'store';
import * as actions from 'actions';
import Button from 'components/button';

const cms = wurd.block('profile.emailConfirm');

export default function () {
  const user = store.get('user');
  const confirmEmailMutation = useMutation(() => actions.user.sendConfirmEmail(user.email));

  if (confirmEmailMutation.isSuccess) return (
    <>
      <div className="alert alert-success d-flex align-items-baseline gap-2">
        <div className="flex-1">
          <i className="far fa-check-circle me-2"></i>
          <cms.Text id="sent" />
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="alert alert-warning d-flex align-items-baseline gap-2">
        <div className="flex-1">
          <i className="far fa-exclamation-circle me-2"></i>
          <cms.Text id="text" />
        </div>
        <Button
          variant="warning"
          size="sm"
          type="button"
          className="ms-1"
          onClick={confirmEmailMutation.mutate}
          loading={confirmEmailMutation.isLoading}
        >
          <cms.Text id="btn" />
        </Button>
      </div>
    </>
  );
}