import { useState } from 'react';
import wurd from 'wurd-react';
import { getLangText, getPrice } from 'utils/ui';
import pick from 'lodash/pick';
import store from 'store';
import Field from 'components/field';
import * as actions from 'actions';
import { useParams } from 'react-router-dom';
import CustomFields from 'plugins/custom-fields/fields';
import Captcha from 'plugins/captcha';
import useForm from 'utils/useForm';
import analytics from 'utils/analytics';
import Paper from 'components/paper';
import Block from 'components/block';
import Title from 'components/title';
import ErrMsg from 'components/err-msg';
import Button from 'components/step-button';
import TermsCheck from 'components/terms-check';
import GoogleButton from 'components/google-button';


const cms = wurd.block('valetOrder.area');

export default function ({ nextStep, fieldProps, formValue, showPromoCode }) {
  const { settings, user } = store.get();
  const [showLogin, setShowLogin] = useState();
  const signupForm = useForm({
    initialValue: {
      ...pick(user, ['firstName', 'lastName', 'email', 'customFields']),
      ...pick(formValue, ['firstName', 'lastName', 'email', 'phone', 'address', 'customFields']),
    },
    onSubmit: (data, { email, password, ...patchData }) => !user ? actions.user.signup({ ...data, language: store.get('lang') }) : actions.user.update({ ...patchData, phone: formValue.phone, address: formValue.address }),
    cms,
  });
  const signinForm = useForm({
    onSubmit: actions.user.signin,
    cms,
  });

  async function submit(e) {
    e.preventDefault();
    try {
      if (showLogin) {
        await signinForm.submit();
        setShowLogin(false);
        return;
      }

      await signupForm.submit();

      if (formValue.code) analytics.selectPromotion(formValue);

      nextStep({ area: user?.area ? '' : formValue.area });
    } catch { }
  }

  const selectedArea = formValue.area && settings.areas.find(area => area.id === formValue.area);

  return (
    <>
      <Title cms={cms.block('title')} className="mt-5" />
      <Block as="form" onSubmit={submit} key={showLogin}>
        <Paper padded>
          <Captcha />
          {!user && (
            <div className="text-end"><button type="button" className="btn btn-sm btn-tertiary" onClick={() => setShowLogin(!showLogin)}><cms.Text id={showLogin ? 'signup' : 'login'} /></button></div>
          )}

          {showLogin
            ? (
              <>
                <Field
                  {...signinForm.fieldProps('email', '')}
                  required
                  autoComplete="username"
                />
                <Field
                  {...signinForm.fieldProps('password', '')}
                  required
                  type="password"
                  autoComplete="current-password"
                />
              </>
            ) : (
              <>
                <Field {...signupForm.fieldProps('firstName')} required />
                <Field {...signupForm.fieldProps('lastName')} required />
                <Field
                  {...signupForm.fieldProps('email')}
                  value={user?.email}
                  required
                  type="email"
                  autoComplete="username"
                />
                {!user && <Field {...signupForm.fieldProps('password')} required type="password" autoComplete="current-password" />}

                <Field {...fieldProps('phone', user?.phone)} required />
                <Field {...fieldProps('address', user?.address)}>
                  <textarea required />
                </Field>
                <Field
                  {...fieldProps('area', user?.area)}
                  keys="label,placeholder,surcharge"
                  help={
                    selectedArea && getLangText(selectedArea.note) && (
                      <div className="alert alert-warning text-muted">
                        {getLangText(selectedArea.note)}
                      </div>
                    )
                  }
                >
                  <select required>
                    <option value="">{cms.text('area.placeholder')}</option>
                    {settings.areas.map(area => {
                      let label = getLangText(area.title) || area._id || area.id;

                      if (area.surcharge) {
                        label += ' ' + cms.text('area.surcharge', {
                          surcharge: getPrice(area.surcharge)
                        });
                      }

                      return (
                        <option key={area._id || area.id} value={area._id || area.id}>{label}</option>
                      );
                    })}
                  </select>
                </Field>
                <Field {...fieldProps('instructions')} />
                <Field {...fieldProps('altPhone')} />

                <CustomFields
                  fieldProps={signupForm.fieldProps}
                  fieldSettings={settings.userCustomFields}
                  mode={user ? 'update' : 'create'}
                />

                {!user && (
                  <>
                    <TermsCheck cms={cms} className="mt-4" />
                    <GoogleButton signup />
                  </>
                )}
              </>
            )}

          {signupForm.err && !showLogin && <ErrMsg err={signupForm.err} className="mt-2" />}

          {signinForm.err && showLogin && <ErrMsg err={signinForm.err} className="mt-2" />}
        </Paper>

        {showPromoCode && !showLogin && (
          <Paper padded className="mt-3">
            <Field {...fieldProps('code')} />
          </Paper>
        )}

        <Button loading={signupForm.loading || signinForm.loading}><cms.Text id="submitText" /></Button>
      </Block>
    </>
  );
}
