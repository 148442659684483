import styled from '@emotion/styled';

export const paperStyles = {
  backgroundColor: '#fff',
  boxShadow: 'var(--shadow-light)',
  borderRadius: 4,
  ':empty': {
    padding: 0
  }
};


export default styled.div(({ padded }) => ({
  ...paperStyles,
  padding: padded ? '1.25rem' : null,
  '.form-floating': {
    marginTop: '.5rem'
  },
}));