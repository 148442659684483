import wurd from 'wurd-react';
import loadScript from 'load-script2';
import * as valet from './valet';
import { getLangText } from './ui';
import store from '../store';


function log(...args) {
  if (process.env.NODE_ENV !== 'development') return;

  console.info('analytics', ...args);
}

export default {
  init(settings) {
    log('init', settings);

    //if (process.env.NODE_ENV !== 'production') return;

    if (settings.segment?.id) {
      init_segment(settings.segment.id);
    }

    if (settings.gtm?.id) {
      log('gtm', settings.gtm.id);
      init_gtm(settings.gtm.id);
    }

    if (settings.ga?.id) {
      if (settings.ga.id.startsWith('G-')) {
        init_gtag(settings.ga.id);
      } else {
        init_ga(settings.ga.id);
      }
    }

    if (settings.fbq?.id) {
      log('fbq', settings.fbq.id);
      init_fbq(settings.fbq.id);
    }

    /* if (location.hostname !== 'localhost') {
      init_clarity();
    } */
  },

  page(name) {
    log('page', name);

    if (window.analytics) {
      window.analytics.page(name);
    }

    if (window.ga) {
      window.ga('send', 'pageview');
    }
  },

  identify(user, method) {
    log('identify', user);

    if (window.analytics) {
      window.analytics.identify(user.id, { method });
    }

    if (window.gtag) {
      window.gtag('event', 'login', { method });
    }
  },

  // track(categoryName, eventName, info = {}) {
  //   log('track', categoryName, eventName, info);

  //   if (window.analytics) {
  //     window.analytics.track(eventName);
  //   }

  //   if (window.ga) {
  //     window.ga('send', 'event', categoryName, eventName);
  //   }
  // },

  signedUp(user, method) {
    log('signedUp', user);

    this.identify(user);

    if (window.analytics) {
      window.analytics.track('Signed Up', {
        userId: user.id,
      });
    }

    if (window.gtag) {
      window.gtag('event', 'sign_up', { method });
    }

    if (window.ga) {
      window.ga('send', 'event', 'get started', 'signup', user.id);
    }

    if (window.fbq) {
      window.fbq('track', 'Lead');
    }
  },

  createdValetOrder(valetOrder) {
    const { settings } = store.get();
    const isBoxOrder = valet.hasOrderedItems(valetOrder) || valet.hasBulkyItems(valetOrder);

    log('createdValetOrder', isBoxOrder ? 'boxes' : 'schedule', valetOrder);

    if (window.analytics) {
      window.analytics.track('Created Valet Order');
    }

    if (window.ga) {
      window.ga('send', 'event', 'get started', isBoxOrder ? 'order boxes' : wurd.text(`my-items.schedule.${valet.getType(valetOrder)}`)); // 'collection' | 'delivery' | 'both' | 'appointment'
    }

    if (window.fbq) {
      window.fbq('track', 'CompleteRegistration');
    }

    if (window.gtag && settings.currencyCode) {
      const { subtotal, tax, plan } = valet.getTotals(valetOrder);
      const { market } = Object.fromEntries(new URLSearchParams(window.location.search));
      const items = [
        ...plan ? [{
          item_id: plan.id,
          item_name: getLangText(plan.title, ''),
          item_list_name: 'Plan',
          item_brand: market,
          price: plan.price,
        }] : [],
        ...settings.items.map(o => {
          const quantity = valetOrder.boxCounts[o.type] || valetOrder.bulkyCounts[o.type];
          if (!quantity) return null;
          return {
            item_id: o.id,
            item_name: o.type,
            item_list_name: 'Boxes',
            item_brand: market,
            price: o.price,
            quantity,
          }
        }).filter(Boolean),
        ...settings.products.map(p => {
          const quantity = valetOrder.productCounts[p.id];
          if (!quantity) return null;
          return {
            item_id: p.id,
            item_name: getLangText(p.title, ''),
            item_list_name: 'Products',
            item_brand: market,
            price: p.price,
            quantity,
          }
        }).filter(Boolean),
      ];

      window.gtag('event', 'purchase', {
        transaction_id: valetOrder.id,
        currency: settings.currencyCode,
        value: +subtotal.toLocaleString('en', { style: 'currency', currency: 'USD' }).slice(1),
        tax: +tax.toLocaleString('en', { style: 'currency', currency: 'USD' }).slice(1),
        items,
      });
    }
  },

  createdUnitOrder(unitOrder, opts = {}) {
    const { settings } = store.get();
    log(opts.start ? 'startedUnitOrder' : 'createdUnitOrder', unitOrder);

    if (window.analytics && !opts.start) {
      window.analytics.track('Created Unit Order');
    }

    if (window.ga && !opts.start) {
      window.ga('send', 'event', 'get started', 'order unit');
    }

    if (window.fbq && !opts.start) {
      window.fbq('track', 'CompleteRegistration');
    }

    if (window.gtag && settings.currencyCode) {
      window.gtag('event', opts.start ? 'begin_checkout' : 'purchase', {
        transaction_id: unitOrder.id,
        currency: settings.currencyCode,
        value: unitOrder.invoicePreview.total,
        tax: unitOrder.invoicePreview.tax,
        items: unitOrder.invoicePreview.entries.map(entry => ({
          item_id: entry.id,
          item_name: entry.desc,
          price: entry.total,
          quantity: entry.qty,
          tax: entry.tax,
          item_list_name: entry.code,
          item_category: entry.type,
          item_brand: settings.companyName,
        })),
      });
    }
  },

  addPaymentInfo(payment_type, is_new) {
    log('addPaymentInfo', payment_type, is_new);

    if (window.gtag) {
      window.gtag('event', 'add_payment_info', { payment_type: `${payment_type}${is_new ? ' (NEW)' : ''}` });
    }
  },

  selectPromotion(data) {
    log('selectPromotion', data);

    if (window.gtag) {
      window.gtag('event', 'select_promotion', {
        promotion_name: data.promoCode,
        creative_name: data.prepayMonths ? `Prepay:${data.prepayMonths}` : undefined
      });
    }
  },

  selectItem(data) {
    const { settings } = store.get();
    log('selectItem', data);

    if (window.gtag && settings.currencyCode) {
      window.gtag('event', 'select_item', {
        ...data,
        currency: settings.currencyCode,
      });
    }
  },
};


// --- snippets

/* eslint-disable */
// Microsoft Clarity for our own tracking
/* export function init_clarity() {
  (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;t.nonce=store.get('nonce');
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", process.env.REACT_APP_CLARITY_ID || "djwz91jag9");
} */

// Segment
function init_segment(id) {
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";t.nonce=store.get('nonce');var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
  analytics.load(id);
  analytics.page();
  }}();
}

// Google Tag Manager
function init_gtm(id) {
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;j.nonce=store.get('nonce');f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer',id);

  window.gtag = function gtag(){dataLayer.push(arguments);}
}

// Google tag (gtag.js)
function init_gtag(id) {
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${id}`, { nonce: store.get('nonce') });

  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', id);
}

// Google Analytics
function init_ga(id) {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date(); a = s.createElement(o),
      m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; a.nonce=store.get('nonce'); m.parentNode.insertBefore(a, m)
  })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

  window.ga('create', id, 'auto');
  window.ga('send', 'pageview');
}

// Facebook Conversion Code for Registration pixel
function init_fbq(id) {
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
      n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }; if (!f._fbq) f._fbq = n;
    n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; t.nonce=store.get('nonce'); s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
  }(window,
    document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  window.fbq('init', id, {
    //em: 'insert_email_variable,'
  });
  window.fbq('track', 'PageView');
}
