import GoogleMap from 'google-map-react';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import wurd from 'wurd-react';
// import mapStyles from './map-styles';
import Marker from './marker';
import UserLocation from './user-location';
import store from 'store';
import useGeolocation from 'utils/useGeolocation';
import { isMobile } from 'utils/useMobile';
import useResize from 'utils/useResize';
import useScroll from 'utils/useScroll';


const cms = wurd.block('siteMap');
const mapsApiKey = 'AIzaSyAp1keXAT8OKjXoU6FV5UGyGJupPQiVQLc';

const getHeight = () => isMobile() ? 250 : window.innerHeight - (57 + 56 - Math.min(56, document.documentElement.scrollTop) + 35 - Math.min(35, document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight)); // header + progress (hides on scroll) + footer (pushed at bottom)

// prevent google maps from loading more fonts (to prevent CSP wwarnings), all our fonts are self-hosted
const insertBefore = document.head.insertBefore;
document.head.insertBefore = function (newElement, referenceElement) {
  if (newElement.href?.startsWith('https://fonts.googleapis.com/css?family=')) return;
  insertBefore.call(document.head, newElement, referenceElement);
};

export default function SiteMap({ sites, defaultZoom = 13, center }) {
  const userLocation = useGeolocation();
  const navigate = useNavigate();
  const [height, setHeight] = useState(getHeight());
  const mappedSites = sites.filter(site => site.lat && site.lng);
  useResize(() => setHeight(getHeight()));
  useScroll(() => setHeight(getHeight()));
  useEffect(() => {
    setHeight(getHeight());
  }, []);

  function clickMap(mapEvent) {
    // mapEvent.event.target
  }

  const handleApiLoaded = useCallback(({ map }) => {
    if (!window.google) return;

    const bounds = new window.google.maps.LatLngBounds();
    for (const site of mappedSites) {
      bounds.extend(new window.google.maps.LatLng(site.lat, site.lng));
    }

    map.fitBounds(bounds);
    const center = bounds.getCenter();
    const zoom =  Math.min(15, map.getZoom());
    map.setZoom(zoom);
  }, [window.google, mappedSites.length]);

  return (
    <GoogleMap
      style={{ height }}
      bootstrapURLKeys={{ key: mapsApiKey, nonce: store.get('nonce') }}
      defaultZoom={defaultZoom}
      center={center || mappedSites[0] || { lat: 0, lng: 0 }}
      options={{ /*styles: mapStyles,*/ fullscreenControl: false }}
      onClick={clickMap}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleApiLoaded}
    >
      {mappedSites.map(site => (
        <Marker
          key={site.id}
          site={site}
          lat={site.lat}
          lng={site.lng}
          offset={site.offset}
          onClick={() => navigate(`/sites/${site.code}`)}
        />
      ))}

      {userLocation
        && (
          <UserLocation
            lat={userLocation.lat}
            lng={userLocation.lng}
          />
        )}
    </GoogleMap>
  );
}
