import Fade from 'react-bootstrap/Fade';
import styled from '@emotion/styled';
import Paper from 'components/paper';


const Container = styled.div({
  backgroundColor: '#0004',
  position: 'fixed',
  zIndex: -1,
  userSelect: 'none',
  // inset: 0, // same as below but doens't work on safari
  top: 0, left: 0, bottom: 0, right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  'button[data-bs-dismiss="toast"]': {
    position: 'absolute',
    right: '-2.5rem',
    top: '-3rem',
  },

  '[role="alert"]': {
    width: 'min(100vw, 560px)',
    position: 'relative',
  },
});


export default function Notification({ show, onClose, children }) {
  return (
    <Fade in={show}>
      <Container
        role="dialog"
        aria-live="polite"
        aria-atomic="true"
        style={show ? { zIndex: 1031, userSelect: 'auto' } : null}
        // onClick={e => e.currentTarget === e.target ? onClose(e) : null}
      >
        <Paper className="fade show position-relative" role="alert" aria-live="assertive" aria-atomic="true">
          <button type="button" className="btn text-primary" data-bs-dismiss="toast" aria-label="Close" onClick={onClose}><i className="text-white fal fa-times fa-2x" /></button>
          {children}
        </Paper>
      </Container>
    </Fade>
  );
}
