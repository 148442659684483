import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import store from '../store';
import { hasOrderedItems, isDelivery, isCollection } from 'utils/valet';
import { getItemTitle, getOrder, toCsv } from 'utils/item';
import { getLangText } from 'utils/ui';
import { download } from 'utils/csv';
import useSearchData from 'utils/useSearchData';

import ItemList from 'components/item/list';
import ItemModal from 'components/item/modal';
import ItemSearch from 'components/item/search';
import SelectedItemsBar from 'components/selected-items-bar';
import UpcomingOrders from 'components/valet-upcoming-orders';
import Button from 'components/button';
import SuccessOrderBox from 'components/success-order-box';
import Notification from 'components/notification';
import Empty from 'components/empty';
import Title from 'components/title';
import Block from 'components/block';


const cms = wurd.block('my-items');

const NavLink = styled(Nav.Link)({
  '&:not(.active)': {
    filter: 'opacity(.6)'
  },
  '&.active': {
    textDecoration: 'underline',
    textUnderlineOffset: '1rem',
    textDecorationThickness: 4,
  }
});

export default function MyItems() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [modalItem, toggleModal] = useState(null);
  const [activeTab, setActiveTab] = useState('items-user');
  const [filter, setFilter] = useState('');
  const [viewMode, setViewMode] = useState(localStorage.getItem('sg-layout') || 'grid');
  const [sd, setSearchData] = useSearchData();
  const location = useLocation();
  const { settings, user } = store.get();

  useEffect(() => {
    const el = document.getElementById(location.hash.slice(1));
    if (el?.scrollIntoViewIfNeeded) {
      el.scrollIntoViewIfNeeded();
    }
  }, [user, location.hash]);

  const bulkyTypes = settings.items.filter(item => item.type.startsWith('bulky'));

  // valet data
  const searches = filter.toLowerCase().split(/[, ]+/);
  const items = user?.items.filter(item => {
    return [
      item.sid,
      getItemTitle(item).toLowerCase(),
      item.desc.toLowerCase(),
      ...Object.entries(item.customFields || {}).map(([k, v]) => `${k}:${v}`),
    ].some(term => searches.some(search => term.includes(search)));
  });
  const itemsWithUser = items?.filter(item => item.state === 'user') || [];
  const itemsInStorage = items?.filter(item => item.state !== 'user') || [];
  const nextDelivery = user?.valetOrders.find(isDelivery);
  const nextCollection = user?.valetOrders.find(isCollection);
  const hasEmptyBoxDelivery = nextDelivery && hasOrderedItems(nextDelivery);

  useEffect(() => {
    if (itemsWithUser.length === 0 && itemsInStorage.length > 0) {
      setActiveTab('items-storage');
    }
  }, [user]);

  function addTempItem(type) {
    toggleModal({
      title: '',
      desc: '',
      type,
      state: 'user',
    });
  }

  function toggleSelected({ id }) {
    setSelectedItems(selItems => selItems.includes(id) ? selItems.filter(v => v !== id) : [...selItems, id]);
  }

  function selectAllItems() {
    const visibleItems = activeTab === 'items-storage' ? itemsInStorage : itemsWithUser;
    setSelectedItems(visibleItems.filter(item => !getOrder(item)).map(item => item.id));
  }

  function toggleViewMode() {
    const mode = viewMode === 'list' ? 'grid' : 'list';
    setViewMode(mode);
    localStorage.setItem('sg-layout', mode);
  }

  function downloadCsv() {
    download(
      toCsv(activeTab === 'items-storage' ? itemsInStorage : itemsWithUser),
      cms.text(activeTab === 'items-storage' ? 'inStorage' : 'withYou') + '.csv'
    ).then(() => {
      setActiveTab(activeTab); // needed else for some reason tabs are unselected
    });
  }

  const bulkyButton = user && bulkyTypes.length > 0 && (
    <div className="mt-5 text-center mb-1">
      {bulkyTypes.length === 1
        ? (
          <Button onClick={() => addTempItem(bulkyTypes[0].type)}><i className="far fa-plus me-1" /><cms.Text id="withUser.addBulky" /></Button>
        ) : (
          <DropdownButton id="add-bulky" title={<cms.Text id="withUser.addBulky" />}>
            {bulkyTypes.map(item => (
              <Dropdown.Item key={item.sid} onClick={() => addTempItem(item.type)}>
                <i className="far fa-plus me-1" />{getLangText(item.title)}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
    </div>
  );

  if (user === undefined) return null; // wait for load

  return (
    <div className="container mb-2">
      {user?.valetOrders.length > 0 && (
        <>
          <Title cms={cms.block('appointments')} as="h3" className="my-4 h5" />
          <UpcomingOrders user={user} toggleModal={toggleModal} />
        </>
      )}

      {user === null || user?.items.length === 0 && !nextDelivery
        ? (
          <Empty cms={cms.block('empty')}>
            {new URLSearchParams(location.search).get('cancelled') !== null && (
              <cms.Markdown id="empty.cancelled" />
            )}
            <div className="mt-5 text-center">
              <Button as={Link} to={`/valet${location.search}`} onClick={() => sessionStorage.removeItem('valet-order')}>
                <i className="far fa-archive me-1" />
                <cms.Text id="orderBoxes" />
              </Button>
            </div>

            {bulkyButton}
          </Empty>
        ) : (
          <Tab.Container
            variant="pills"
            className="mt-5"
            activeKey={activeTab}
            onSelect={key => { setSelectedItems([]); setActiveTab(key); }}
            id="items-tabs"
          >
            <Nav variant="pills" className="justify-content-center mb-2 gap-md-4">
              <Nav.Item>
                <NavLink eventKey="items-user"><cms.Text id="withYou" /></NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink eventKey="items-storage"><cms.Text id="inStorage" /></NavLink>
              </Nav.Item>
            </Nav>

            <div className="d-flex justify-content-between align-items-center" style={{ padding: '.25rem 0' }}>
              <ItemSearch value={filter} onChange={e => setFilter(e.target.value)} />
              <cms.Object id="menu" keys="export" type="div" className="d-flex align-items-center">
                <Button
                  size="sm"
                  variant="default"
                  className="px-2"
                  onClick={toggleViewMode}
                >
                  <i className={`fa-fw far ${viewMode === 'list' ? 'fa-grip-horizontal' : 'fa-grip-lines'}`} />
                </Button>
                <DropdownButton className="no-caret" style={{ marginLeft: -8 }} id="items-menu" size="sm" variant="light" title={<i className="far fa-ellipsis-v" />}>
                  <Dropdown.Item as="button" eventKey="export" onClick={downloadCsv}>
                    <i className="fas fa-download me-1" />{cms.text('menu.export')}
                  </Dropdown.Item>
                </DropdownButton>
              </cms.Object>
            </div>

            <Tab.Content className="mb-4">
              <Tab.Pane eventKey="items-user">
                {itemsWithUser.length > 0
                  ? (
                    <ItemList
                      items={itemsWithUser}
                      toggleModal={toggleModal}
                      selectedItems={selectedItems}
                      toggleSelected={toggleSelected}
                      viewMode={viewMode}
                    />
                  ) : (
                    <Block className="mt-5 text-center text-muted">
                      {
                        itemsInStorage.length === 0 && hasEmptyBoxDelivery
                          ? <cms.Markdown id="empty_user_hasOrdered" />
                          : itemsInStorage.length > 0
                            ? <cms.Markdown id="empty_user_hasItemsInStorage" />
                            : <cms.Markdown id="empty_user" />
                      }
                    </Block>
                  )}

                {bulkyButton}
              </Tab.Pane>
              <Tab.Pane eventKey="items-storage">
                {itemsInStorage.length > 0
                  ? (
                    <ItemList
                      items={itemsInStorage}
                      toggleModal={toggleModal}
                      selectedItems={selectedItems}
                      toggleSelected={toggleSelected}
                      viewMode={viewMode}
                    />
                  ) : (
                    <Block className="mt-5 text-center text-muted">
                      {
                        itemsWithUser.length > 0 && !nextCollection
                          ? <cms.Markdown id="empty_storage_hasItemsWithUser" />
                          : <cms.Markdown id="empty_storage" />
                      }
                    </Block>
                  )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        )}

      <Notification show={!!sd.order} onClose={() => setSearchData({})}>
        {sd.order && (
          <SuccessOrderBox
            cms={wurd.block('valetOrder.success')}
            btnId="myOrders"
            btnLink={`/account/items#order_${sd.order}`}
          />
        )}
      </Notification>

      {selectedItems.length > 0 && (
        <SelectedItemsBar
          type={activeTab.slice(6)}
          selectedItems={selectedItems}
          onCancel={() => setSelectedItems([])}
          onSelectAll={selectAllItems}
        />
      )}

      <Modal id="itemModal" show={!!modalItem} onHide={() => toggleModal()} className="modal-sm">
        <button type="button" className="btn text-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => toggleModal()}><i className="text-white fal fa-times fa-2x" /></button>
        <ItemModal
          item={modalItem}
          close={() => toggleModal()}
        />
      </Modal>
    </div>
  );
}
