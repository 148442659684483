import { useMemo } from 'react';


export default function useSessionStorage(key, deps) {
  const cache = useMemo(() => { // used for keeping old values when going back in previous steps
    if (!sessionStorage.getItem(key)) return null;
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch { }
  }, deps);

  return {
    data: cache,
    set: d => sessionStorage.setItem(key, JSON.stringify({ ...cache, ...d })),
    del: () => sessionStorage.removeItem(key),
  };
}
