import styled from '@emotion/styled';
import wurd from 'wurd-react';
import { Link } from 'react-router-dom';
import Button from './button';


const cms = wurd.block('my-items.selectedBar');

const Container = styled.div({
  position: 'sticky',
  left: 0,
  right: 0,
  bottom: '2rem',
  display: 'flex',
  justifyContent: 'center',

  '@media only screen and (max-width: 992px)': {
    bottom: 0,
    '.btn': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    }
  },

  'nav': {
    backgroundColor: '#fff',
    boxShadow: '0 0 2.5rem 0 rgba(0,0,0,.25)',
    padding: '.75rem 1.25rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '.5rem',

    '@media only screen and (min-width: 992px)': {
      padding: '1.25rem 2.5rem',
      '> :first-child': {
        borderRight: '1px solid #0005'
      },
    }
  }
});

export default function SelectedItemsBar({ type /*'user'|'storage'*/, selectedItems, onCancel, onSelectAll }) {
  return (
    <Container>
      <nav className="navbar rounded">
        <div className="d-flex align-items-center pe-md-2">
          <cms.Text type="strong" id="numSelected" vars={{ num: selectedItems.length }}  />
          {onSelectAll && <button className="btn btn-link ms-2" onClick={onSelectAll}><cms.Text id="selectAll" /></button>}
        </div>
        <div className="ps-md-4">
          <Button as={Link} to={`/account/${type === 'user' ? 'collect' : 'deliver'}/${selectedItems.join(',')}`}>
            <i className="fal fa-truck me-1 me-lg-2" /><cms.Text id={`schedule_${type}`} /><i className="fal fa-arrow-right ms-1 ms-lg-2 d-none d-sm-inline-block" />
          </Button>
          <Button type="button" variant="default" onClick={onCancel}>
            <cms.Text id="cancel" /><i className="fal fa-times ms-1 ms-lg-2 d-none d-sm-inline-block" />
          </Button>
        </div>
      </nav>
    </Container>
  )
}
