import api from '../utils/api';
import analytics from '../utils/analytics';
import store from '../store';
import errHandler from './_err-handler';


export function create(data) {
  return api.post('/v1/valet/orders', data)
    .then(order => {
      const user = store.get('user');
      store.set({
        user: { ...user, valetOrders: [...user.valetOrders, order] }
      });

      analytics.createdValetOrder(order);

      return order;
    })
    .catch(errHandler);
}

export function update(id, data) {
  return api.put(`/v1/valet/orders/${id}`, data)
    .catch(errHandler);
}

export function cancelValetOrder(id) {
  return api.put(`/v1/valet/orders/${id}/cancel`)
    .then(cancelled => {
      const user = store.get('user');
      store.set({ user: { ...user, valetOrders: user.valetOrders.filter(o => o.id !== id) } });
    });
}

export function fetchValetOrder(id) {
  return api.get(`/v1/valet/orders/${id}`);
}
