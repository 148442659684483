import { Link } from 'react-router-dom';
import Button from 'components/button';
import Title from 'components/title';
import store from 'store';


export default function SuccessOrder_Box({ cms, btnId, btnLink }) {
  const user = store.get('user');

  return (
    <div className="p-4">
      <div className="d-flex align-items-baseline gap-2 text-primary">
        <i className="fas fa-check-circle" style={{ fontSize: '150%' }} />
        <Title cms={cms.block('title')} as="h3" />
      </div>
      <cms.Markdown id="info" vars={{ email: user?.email }} className="text-muted my-4 text-start fs-5" />
      <div className="pt-4 mt-4 text-end border-top border-light">
        <Button as={Link} to={btnLink}>
          <cms.Text id={btnId} />
        </Button>
      </div>
    </div>
  )
}
