import styled from '@emotion/styled';
import wurd from 'wurd-react';


const cms = wurd.block('my-items.search');

const Input = styled.input({
  maxWidth: '25ch',
  '&::placeholder': {
    opacity: .6,
  }
});


export default (props) => {
  return (
    <cms.Object keys="placeholder" type="div" className="position-relative">
      <Input
        placeholder={cms.text('placeholder')}
        className="form-control form-control-sm"
        {...props}
      />
      {!!props.value && (
        <i
          className="fal fa-times position-absolute top-50 translate-middle-y"
          style={{ right: 6 }}
          role="button"
          tabIndex={-1}
          onClick={() => props.onChange({ target: { value: '' } })}
        />
      )}
    </cms.Object>
  );
}