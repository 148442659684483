import styled from '@emotion/styled';
import getDistance from 'geolib/es/getDistance';
import { getLangText, formatDistance } from 'utils/ui';
import { getMapLink } from 'utils/site';

import { Link } from 'react-router-dom';
import ListItemCard from 'components/list-item-card';
import Button from 'components/button';

const SgcDistance = styled.div({
  fontSize: 12,
  color: 'var(--bs-gray)',
});

const SgcInfo = styled.div({
  color: 'var(--bs-gray)',

  'a': {
    color: 'var(--bs-gray)',
  },

  'li': {
    lineHeight: '2em',
  },
});

/* const SgcAvailability = styled.div({
  fontSize: '.75rem',
  padding: '0 .25rem',
  cursor: 'default',
  textAlign: 'center',
  fontWeight: 500,
  color: 'var(--brand-dark)',
}); */

export default function SiteList_SiteCard({
  site,
  cms,
  userLocation,
}) {
  //const availUnits = site.availability?.available;

  const distanceFormatted = site.lat != null && site.lng != null && userLocation && formatDistance(getDistance(userLocation, site));

  return (
    <ListItemCard image={site.image} imageFit={site.imageFit}>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h3 className="mb-0">{getLangText(site.title)}</h3>

        {distanceFormatted && (
          <SgcDistance className="text-nowrap">{distanceFormatted}</SgcDistance>
        )}
      </div>

      <SgcInfo className="mb-2">
        <ul className="list-unstyled">
          {getLangText(site.address) && (
            <li className="text-truncate">
              <i className="fal fa-map-marker-alt fa-fw me-1" /><a href={getMapLink(site)} target="_blank" className="text-decoration-none">{getLangText(site.address)}</a>
            </li>
          )}
          {site.phone && (
            <li>
              <i className="fal fa-phone-alt fa-fw me-1" /><a href={`tel:${site.phone.replace(/\s/g, '')}`} className="text-decoration-none">{site.phone}</a>
            </li>
          )}
        </ul>
      </SgcInfo>

      <div className="d-flex align-items-center justify-content-between gap-1 mt-auto">
        {/* <SgcAvailability data-availability={availUnits}>
          <cms.Text id={availUnits ? 'availNum' : 'noAvail'} vars={{ num: availUnits }} />
        </SgcAvailability> */}

        <div>{/* Empty for now but can be used for info text etc. */}</div>

        <Button icon="next" as={Link} to={`/sites/${site.code}`} className="px-2 px-md-4">
          <cms.Text id="chooseSite" />
        </Button>
      </div>
    </ListItemCard>
  );
}
