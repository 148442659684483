import styled from '@emotion/styled';
import wurd from 'wurd-react';

import { getItemTitle, getOrder, getImage } from 'utils/item';

import SgButton from 'components/button';
import Paper from 'components/paper';

const defaultCms = wurd.block('my-items.card');

export const shadowGradient = {
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0, left: 0, bottom: 0, right: 0,
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.11) 36%, rgba(0, 0, 0, 0) 86%)',
  }
};

export const selectButton = {
  backgroundColor: '#fff',
  color: 'var(--bs-gray)',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  fontWeight: 'normal',
  paddingLeft: '.75rem',
  paddingRight: '.75rem',
  gap: '.5rem',
  ':hover': {
    color: '#fff!important'
  },
  '> :not(i)': {
    flex: 1
  },
  '&.selected': {
    backgroundColor: 'var(--brand)',
    color: '#fff!important'
  }
};

const Wrapper = styled.div({
  borderRadius: 4,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  overflow: 'hidden',
  minHeight: 200,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  ...shadowGradient,
});

const Button = styled(SgButton)({
  ...selectButton,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
});

const Info = styled.div({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  color: '#fff',
  //textShadow: '0 1px 0 #000', // Could be applied if text is too faint
  padding: '.75rem 1rem',
  display: 'flex',
  flexDirection: 'column',
});


export default function ItemCard({
  item,
  onSelect,
  isSelected,
  onDelete,
  toggleModal,
  toggleSelected,
  cms = defaultCms,
  disabled = order => !!order,
}) {
  // Check if the item is attached to an valet order
  // TODO: This could be done more efficiently; e.g. add this as metadata when the items come in from API?
  const order = getOrder(item);
  const itemImage = getImage(item);
  const btn = cms.block(item.state);

  return (
    <li className="col">
      <Paper>
        <Wrapper style={{ backgroundImage: `url(${itemImage})` }} onClick={toggleModal ? () => toggleModal(item.id) : null}>
          <Info>
            <strong className="fw-600">{getItemTitle(item)}</strong>
            <small className="text-uppercase">{item.sid}</small>
          </Info>
        </Wrapper>
        <Button
          type="button"
          className={`w-100 text-muted ${isSelected ? 'selected' : ''}`}
          disabled={disabled(order)}
          onClick={() => toggleSelected(item, !isSelected)}
        >
          <i className="fal fa-truck" />
          {order && disabled(order)
            ? (
              <btn.Text id="scheduled" vars={{ sid: order.sid.toUpperCase() }} />
            ) : isSelected
              ? (
                <><btn.Text id="selected" /><i className="fas fa-check-circle" /></>
              ) : (
                <><btn.Text id="unselected" /><i className="fal fa-circle" style={{ filter: 'opacity(.5)' }} /></>
              )
          }
        </Button>
      </Paper>
    </li>
  );
}
