import { useRef } from 'react';


export default function Button({
  className = '',
  variant = 'primary',
  size,
  as: Container = 'button',
  icon = '',
  disabled,
  loading,
  onClick,
  children,
  ...rest
}) {
  const buttonRef = useRef();

  const handleClick = e => {
    buttonRef.current.disabled = true; // disable here from DOM instead of in react because react is async and can allow fast duplicates
    Promise.resolve()
      .then(onClick)
      .finally(() => {
        if (buttonRef.current) buttonRef.current.disabled = disabled || false;
      });
  };

  return (
    <Container
      ref={buttonRef}
      className={`btn btn-${variant} ${size ? `btn-${size}` : ''} ${className}`}
      disabled={disabled || loading}
      onClick={handleClick}
      {...rest}
    >
      {loading && <i className="far fa-spinner fa-spin" style={{ marginRight: '.5rem' }} />}

      {children}

      {icon === 'next' && (
        <i className="far fa-arrow-right ms-1" />
      )}
    </Container>
  );
}
