/**
 * A super simple store for global data
 * such as user info etc.
 */
let storeData = {
  settings: undefined,
  user: undefined,
  language: undefined,
  nonce: document.querySelector('script[nonce]')?.nonce, // grab script nonce, so we pass it to other script that we add
};

const subscriptions = new Set();

/**
 * Get store data
 *
 * Usage:
 * const key = store.get('key')
 * const { key } = store.get()
 *
 * @param {String} [key]
 *
 * @return {Mixed}
 */
function get(key) {
  if (key) return storeData[key];

  return storeData;
}


/**
 * Set store data and trigger onChange listeners.
 * Merges given object data into the existing store data.
 *
 * Usage:
 * store.set({ foo: 'bar' });
 *
 * @param {String} key
 *
 * @return {Mixed}
 */
function set(newData) {
  const oldData = storeData;
  storeData = { ...oldData, ...newData };
  subscriptions.forEach(fn => {
    fn(storeData, oldData);
  });
}


function subscribe(fn) {
  subscriptions.add(fn);
}

function unsubscribe(fn) {
  subscriptions.delete(fn);
}


export default {
  get,
  set,
  subscribe,
  unsubscribe,
};
