import { useLocation } from 'react-router-dom';
import wurd from 'wurd-react';
import store from '../store';
import { hasBillingMethod } from '../utils/user';
import useSearchData from 'utils/useSearchData';
import useCache from 'utils/useCache';
import AreaStep from '../components/valet-order-steps/area';
import TimeStep from '../components/valet-order-steps/time';
import BillingStep from '../components/valet-order-steps/billing';
import ConfirmStep from 'components/valet-order-steps/confirm';
import Loader from 'components/loader';
import ErrMsg from 'components/err-msg';
import useForm from 'utils/useForm';


const cms = wurd.block('valetOrder');

const stepComponents = {
  area: AreaStep,
  time: TimeStep,
  billing: BillingStep,
  confirm: ConfirmStep,
}

/*
Valet order schedule
*/
function getStep(data) {
  const { settings, user } = store.get();

  if (!user || data.area === undefined || !(user.phone || data.phone) || !(user.address || data.address)) return 'area';

  if (!data.date || !data.timeslot) return 'time';

  if (!hasBillingMethod(settings, user) || data.session_id/* stripe-checkout callback */) return 'billing';

  return 'confirm';
}

export default function () {
  const [data, setData] = useSearchData();
  const cache = useCache({});
  const location = useLocation();
  const { user } = store.get();
  const [_account, type, items] = location.pathname.slice(1).split('/'); // /account/{collect|deliver}/{itemIds}

  const step = getStep(data);

  const combinedData = { ...cache.data, ...data };

  const formProps = useForm({
    initialValue: combinedData,
    cms: cms.block(step),
  });

  const StepComponent = stepComponents[step];

  const nextStep = (d) => {
    cache.set({ ...formProps.formValue });
    setData({ ...data, ...formProps.formValue, ...d });
  };

  return (
    <div className="container">
      <div className="alert alert-info">
        <cms.Markdown id={`schedule.${type}`} vars={{ items: items.split(',').map(id => user?.items.find(o => o.id === id)?.sid.toUpperCase()) }} />
      </div>

      {StepComponent && (
        <StepComponent
          {...combinedData}
          data={data}
          {...formProps}
          nextStep={nextStep}
        />
      )}
    </div>
  );
}
