import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import wurd from 'wurd-react';
import { useQuery } from 'react-query';

import config from 'config';
import store from 'store';
import * as actions from 'actions';
import { hasBillingMethod } from '../utils/user';

import useSearchData from 'utils/useSearchData';
import Split from 'components/split-page';
import Loader from 'components/loader';
import Progress from 'components/unit-progress';
import Summary from 'components/unit-order/summary';

import AccountStep from 'components/unit-order/steps/account';
import PrepayStep from 'components/unit-order/steps/prepay';
import ProductsStep from 'components/unit-order/steps/products';
import EsignStep from 'components/unit-order/steps/esign';
import BillingStep from 'components/unit-order/steps/billing';
import IdentityStep from 'components/unit-order/steps/identity';
import ReviewStep from 'components/unit-order/steps/review';
import SuccessStep from 'components/unit-order/steps/success';

const stepComponents = {
  account: AccountStep,
  prepay: PrepayStep,
  products: ProductsStep,
  billing: BillingStep,
  identity: IdentityStep,
  esign: EsignStep,
  review: ReviewStep,
  success: SuccessStep,
};


const cms = wurd.block('unitOrder');

export default function UnitOrderPage() {
  const { site: siteCode, type: typeCode, date } = useParams();
  const [data, setData] = useSearchData();
  const { settings, user, unitOrder: order } = store.get();

  // Load site
  const { data: site, ...siteQuery } = useQuery({
    queryKey: ['site', siteCode],
    queryFn: () => actions.sites.get(siteCode),
    staleTime: config.queries.site.staleTime,
  });

  // Load unitType
  const { data: unitType, ...unitTypeQuery } = useQuery({
    queryKey: ['unitType', { siteCode, typeCode }],
    queryFn: () => actions.unitTypes.get(siteCode, typeCode),
    // TODO: load initialData from unitTypes list query, but react-query does not make this easy since they are fetched in groups
  });

  // Ensure we have the order (on browser reload etc)
  useEffect(() => {
    if (!site || !unitType) return;

    actions.unitOrders.fetchCurrent({ siteId: site.id, unitTypeId: unitType.id });
  }, [site?.id, unitType?.id]);

  if (siteQuery.isLoading || unitTypeQuery.isLoading) return <Loader />;

  if (!site || !unitType) return <h1><cms.Text id="notFound" /></h1>;

  function getStep() {
    if (data.session_id) return 'billing'; // stripe or cybersource checkout callback

    if (!order || !user) return 'account';

    //if (!data.prepayMonths) return 'prepay';

    if (site.products?.length > 0 && data.products === undefined) return 'products';

    if (!hasBillingMethod(settings, user, 'setup') && data.pay !== '✓') return 'billing';

    if (site.enableMoveInAgreement && !data.signatureUrl) return 'esign';

    if (order?.identityStatus === null) return 'identity';

    if (order?.orderState === 'notSubmitted') return 'review';

    return 'success';
  }

  const step = getStep();

  const StepComponent = stepComponents[step];
  if (!StepComponent) return null;

  async function nextStep(stepData, opts) {
    try {
      if (!order) {
        await actions.unitOrders.create(stepData);
      } else {
        await actions.unitOrders.updateCurrent({ siteId: site.id, unitTypeId: unitType.id }, stepData);
      }

      setData({
        ...data,
        ...stepData,
        signatureUrl: stepData.signatureUrl && '✓' || data.signatureUrl, // it's too large else in URL
        siteId: undefined, // don't show, not needed as they are saved in the unitOrder
        unitTypeId: undefined,
        startDate: undefined,
        startNow: undefined,
      }, opts);
      window.scrollTo(0, 0);
    } catch (err) {
      return err;
    }
  }


  return (
    <>
      <Progress site={site} unitType={unitType} step={step} />

      <Split className="position-relative align-items-lg-start">
        <Summary
          className="order-lg-2"
          site={site}
          unitType={unitType}
          date={date}
          order={order}
        />

        <div className="flex-1 px-2 px-sm-0">
          <StepComponent
            site={site}
            unitType={unitType}
            date={date}
            order={order}
            nextStep={nextStep}
          />
        </div>
      </Split>
    </>
  );
}
