import wurd from 'wurd-react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import * as actions from 'actions';
import { getLangText } from 'utils/ui';

import { paperStyles } from 'components/paper';
import Button from 'components/button';
import SiteCard from 'components/unit/site-card';
import UnitCard from 'components/unit/unit-card';
import Loader from 'components/loader';

const cms = wurd.block('my-units');

const Section = styled.section({
  '@media (min-width: 992px)': {
    ...paperStyles,
    overflow: 'hidden', // just to make borderRadius of children appear
    display: 'flex',
  },
});

const SectionTitle = styled.h4({
  '@media (max-width: 992px)': {
    padding: '1.25rem .5rem .25rem',
    fontSize: '.875rem!important',
    '.fs-5': { fontSize: '.75rem!important' }
  }
});

const SiteContainer = styled(SiteCard)({
  flex: 1,
  borderRadius: 0,
  border: 0,
  // borderBottom: ''var(--border-light)',
  '@media (min-width: 992px)': {
    borderBottom: 0,
    borderRight: 'var(--border-light)',
  },
});

const UnitsContainer = styled.div({
  flex: 2,
  'li > *': {
    borderBottom: 'var(--border-light)',
  },
  '@media (max-width: 576px)': {
    margin: '0 .5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.75rem',
    '> li > div': paperStyles,
  },
  '@media (min-width: 576px) and (max-width: 992px)': paperStyles,
});


export default function MyUnits_UnitsBySite({
  setModal,
  site,
  units,
}) {
  const typeIds = [...new Set(units.map(u => u.typeId) || [])];
  const { data: unitTypes } = useQuery(['unitTypes', typeIds.join()], () => actions.unitTypes.fetch(site.id, { ids: typeIds }));

  return (
    <div key={site.id}>
      <h4 className="mb-4 d-none d-lg-block"><cms.Text id="subtitle" className="fs-5 fw-normal" />{cms.text('subtitle') && <span className="ms-1">{getLangText(site.title)}</span>}</h4>
      <Section key={site.id} id={site.code}>
        <SiteContainer site={site} className="site" showInfos={false} />

        <SectionTitle className="d-lg-none">
          <cms.Text id="subtitle" className="fs-5 fw-normal" />
          {cms.text('subtitle') && <span className="ms-1">{getLangText(site.title)}</span>}
        </SectionTitle>

        <UnitsContainer>
          <ul className="list-unstyled">
            {units.map(unit => (
              <li key={unit.id} id={`${site.code}-${unit.name}`}>
                <UnitCard
                  unit={unit}
                  site={site}
                  unitType={unitTypes?.find(type => type.id === unit.typeId)}
                  setModal={setModal}
                />
              </li>
            ))}
          </ul>

          <div className="text-center">
            <Button as={Link} to={`/sites/${site.code}`} className="my-2 mt-md-4" onClick={() => sessionStorage.removeItem('unit-order')}>
              <cms.Text id="site.book" />
            </Button>
          </div>

        </UnitsContainer>
      </Section>
    </div>
  );
}
