import { Fragment } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import { getLangText, getPrice } from 'utils/ui';
import store from 'store';
import { getTotals, getTimeslot, hasOrderedItems } from 'utils/valet';
import useMobile from 'utils/useMobile';
import Dl from 'components/dl';

const cms = wurd.block('valetOrder.summary');

const Fit = styled.span({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, 80px)',
  gap: '0 .5rem',
  justifyContent: 'end'
});

const Accordion = styled.div({
  position: 'sticky',
  top: 58,
  zIndex: 3,
  width: 'min(564px, 100%)',
  '.accordion-item': {
    border: 'var(--border-light)!important',
    '@media only screen and (max-width: 564px)': {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottomWidth: 1,
    }
  },
  '@media only screen and (min-width: 992px)': {
    width: 400,
    marginTop: '2rem',
    borderRadius: 4,
    boxShadow: 'var(--shadow-light)',
    overflow: 'hidden',
    '.accordion-collapse': {
      paddingTop: '2rem!important'
    },
  },
  hr: {
    opacity: 1,
  }
});

const CollapsibleButton = props => <button {...props} className="accordion-button collapsed d-flex" type="button" data-bs-toggle="collapse" data-bs-target="#orderSummmary-details" aria-expanded="false"
  aria-controls="orderSummmary-details" />;

function Time({ date, timeslot }) {
  if (!date) return null;

  const { settings } = store.get();

  return (
    <Fit>
      <time dateTime={date} className="text-nowrap">{moment(date).format(settings.dateFormats.short)}</time>
      {timeslot ? <span className="text-nowrap">{wurd.text('valetOrder.time.timeslot.text', getTimeslot(timeslot))}</span> : null}
    </Fit>
  );
}

export default function SummaryPreview({ order, className }) {
  const settings = store.get('settings');
  const { subtotal, tax: taxAmount, total, plan } = getTotals(order);
  const selectedArea = order.area && settings.areas.find(area => area.id === order.area);
  const isMobile = useMobile();

  const boxesEntries = Object.entries({ ...order.boxCounts, ...order.bulkyCounts }).filter(([type, count]) => {
    const item = settings.items.find(o => o.type === type);
    return count > 0 && item;
  });

  const productsEntries = Object.entries(order.productCounts).filter(([productId, count]) => {
    const product = settings.products.find(o => o.id === productId);
    return count > 0 && product;
  });

  const summary = (
    <>
      <Dl>
        {order.address && <><dt><cms.Text id="address" /></dt><dd>{order.address}</dd></>}
        {order.phone && <><dt><cms.Text id="phone" /></dt><dd>{order.phone}</dd></>}
        {order.promoCode && <><dt><cms.Text id="promoCode" /></dt><dd>{order.promoCode}</dd></>}
        {hasOrderedItems(order) && order.collect !== 'immediately'
          ? (
            <>
              <dt><cms.Text id="deliverDate" /></dt>
              <dd><Time date={order.date} timeslot={order.timeslot} /></dd>

              <dt><cms.Text id="collectDate" /></dt>
              <dd><Time date={order.collectDate} timeslot={order.collectTimeslot}
              /></dd>
            </>
          ) : order.date && (
            <>
              <dt><cms.Text id="collectDate" /></dt>
              <dd><Time date={order.date} timeslot={order.timeslot} /></dd>
            </>
          )
        }
      </Dl>

      {plan && (
        <>
          <hr />
          <h6><cms.Text id="plan" /></h6>
          <Dl>
            <dt>{getLangText(plan.title)}</dt>
            <dd>{getPrice(plan.price)}</dd>
          </Dl>
        </>
      )}

      {boxesEntries.length > 0 && (
        <>
          <hr />
          <h6><cms.Text id="items" /></h6>
          <Dl>
            {boxesEntries.map(([type, count]) => {
              const item = settings.items.find(o => o.type === type);

              return (
                <Fragment key={type}>
                  <dt>{`${getLangText(item.title)}${count > 1 ? ` (x${count})` : ''}`}</dt>
                  <dd>{getPrice(item.price * count)}</dd>
                </Fragment>
              );
            })}
          </Dl>
        </>
      )}

      {productsEntries.length > 0 && (
        <>
          <hr />
          <h6><cms.Text id="products" /></h6>
          <Dl>
            {productsEntries.map(([productId, count]) => {
              const product = settings.products.find(o => o.id === productId);

              return (
                <Fragment key={productId}>
                  <dt>{`${getLangText(product.title)}${count > 1 ? ` (x${count})` : ''}`}</dt>
                  <dd>{getPrice(product.price * count)}</dd>
                </Fragment>
              );
            })}
          </Dl>
        </>
      )}

      {selectedArea?.surcharge > 0 && (
        <>
          <hr />
          <h6><cms.Text id="areaSurcharge" /></h6>
          <Dl>
            <dt>{getLangText(selectedArea.title)}</dt>
            <dd>{getPrice(selectedArea.surcharge)}</dd>
          </Dl>
        </>
      )}

      {settings.tax?.percent > 0 && (
        <>
          <hr />
          <Dl>
            <dt><cms.Text id="subtotal" className="fw-600" /></dt>
            <dd>{getPrice(subtotal)}</dd>

            <dt><cms.Text id="tax" className="fw-600" vars={{ amount: settings.tax.percent }} /></dt>
            <dd>{getPrice(taxAmount)}</dd>
          </Dl>
        </>
      )}
    </>
  );

  const Button = isMobile ? CollapsibleButton : 'span';

  return (
    <Accordion className={`accordion accordion-flush mx-auto ${className || ''}`} id="orderSummmary">
      <div className="accordion-item">
        <div id="orderSummmary-details" className={`accordion-collapse px-4 py-3 collapse ${isMobile ? '' : 'show'}`} aria-labelledby="orderSummmary-title" data-bs-parent="#orderSummmary">
          {summary}
        </div>
        <h2 className="accordion-header" id="orderSummmary-title">
          <Button className="accordion-button d-flex">
            <span className="d-flex flex-1">
              <strong className="flex-1 fw-600">
                <cms.Text id="total" />
                {/*settings.tax?.percent > 0 && (
                  <div className="text-muted fs-6" style={{ fontWeight: 'normal', marginTop: 2 }}>
                    <cms.Text id="taxInc" vars={{ amount: getPrice(taxAmount) }} />
                  </div>
                )*/}
              </strong>
              <strong className={`fw-600 ${isMobile ? 'me-2' : ''}`} style={{ alignSelf: 'center' }}>{getPrice(total)}</strong>
            </span>
          </Button>
        </h2>
      </div>
    </Accordion>
  );
}
