import { useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import isPojo from 'lodash/isPlainObject';

// there's a react-router-dom useSearchParams, but serialization will encode ":" and ",", so let's skip it and encode ourselves
export default function useSearchData() {
  const location = useLocation();
  const navigate = useNavigate();

  const data = useMemo(() => {
    return Object.fromEntries(
      [...new URLSearchParams(location.search)]
        .map(([k, v]) => [k, v.includes(':') && !v.startsWith('data:') ? Object.fromEntries(v.split(',').map(s => s.split(':'))) : decodeURIComponent(v)])
    )
  }, [location.search]);

  const setData = useCallback((obj, opts) => {
    const search = Object.entries(obj)
      .filter(([k, v]) => v !== undefined)
      .map(([k, v]) => [
        k,
        isPojo(v)
          ? Object.entries(v).filter(([n, x]) => typeof x === 'number' ? x > 0 : x != null).map(([n, x]) => `${n}:${x}`).join(',')
          : v
      ])
      .map(([k, v]) => [k, v].filter(Boolean).join('='))
      .join('&');

    navigate(`${location.pathname}${search ? `?${search}` : ''}`, opts);
  }, [history]);

  return [data, setData];
}
