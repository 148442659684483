import wurd from 'wurd-react';
import api from '../utils/api';
import store from '../store';
import errHandler from './_err-handler';
import loadImage from 'blueimp-load-image';
import processCustomFieldsData from '../plugins/custom-fields/actions';


export async function create(data) {
  const settings = store.get('settings');
  if (data.customFields) {
    data.customFields = await processCustomFieldsData(data.customFields, settings.itemCustomFields);
  }
  return api.post('/v1/items?include=customFields', data)
    .then(item => {
      const user = store.get('user');
      store.set({
        user: { ...user, items: [...user.items, item] }
      });
      return item;
    })
    .catch(errHandler);
}


export async function update(id, data) {
  const settings = store.get('settings');
  if (data.customFields) {
    data.customFields = await processCustomFieldsData(data.customFields, settings.itemCustomFields);
  }
  return api.put(`/v1/items/${id}?include=customFields`, data)
    .then(item => {
      const user = store.get('user');
      store.set({
        user: { ...user, items: user.items.map(o => o.id === item.id ? item : o) }
      });
      return item;
    })
    .catch(errHandler);
}

export function upsert({ id, ...data }) {
  return id ? update(id, data) : create(data);
}

export async function deleteItem(id) {
  const user = store.get('user');
  const item = user.items.find(item => item.id === id);
  if (!item) return;

  const sid = item.sid.toUpperCase();
  if (!window.confirm(wurd.text('my-items.confirmDelete', { sid }) || `Are you sure to delete item #${sid}?`)) return;

  return api.delete(`/v1/items/${id}`)
    .then(() => {
      const user = store.get('user');
      store.set({
        user: { ...user, items: user.items.filter(o => o.id !== id) }
      });
    })
    .catch(errHandler);
}

export function addItemImage(itemId, file) {
  // Load and resize images before sending, if needed
  const filePromise = file.size < 50000 ? Promise.resolve(file) : loadImage(file, {
    canvas: true,
    maxWidth: 1200,
    maxHeight: 1200
  })
    .then(({ image }) => {
      return new Promise((resolve, reject) => {
        image.toBlob(resolve, 'image/jpeg');
      });
    });

  return filePromise
    .then(blob => {
      const formData = new FormData();

      formData.append('file', blob, file.name || 'photo.jpg');

      return api.post(`/v1/items/${itemId}/images`, formData);
    })
    .then(image => {
      const user = store.get('user');

      store.set({
        user: {
          ...user,
          items: user.items.map(item => item.id === itemId ? { ...item, images: [...item.images, image] } : item)
        }
      });
      return image;
    })
    .catch(errHandler);
}

export function deleteItemImage(itemId, imageId) {
  if (!window.confirm(wurd.text('my-items.images.confirmDelete') || `Are you sure to delete this image?`)) return;

  const ac = new AbortController();
  setTimeout(() => ac.abort(), 8000); // timeout after 8s

  return api.delete(`/v1/items/${itemId}/images/${imageId}`, { signal: ac.signal })
    .then(() => {
      const user = store.get('user');

      store.set({
        user: {
          ...user,
          items: user.items.map(item => item.id === itemId ? { ...item, images: item.images.filter(img => img.id !== imageId) } : item)
        }
      });
    })
    .catch(errHandler);
}

export function selectItemImage(itemId, imageId) {
  return api.put(`/v1/items/${itemId}/images/${imageId}`, { primary: true })
    .then(image => {
      const user = store.get('user');
      store.set({
        user: {
          ...user,
          items: user.items.map(item => item.id === itemId ? { ...item, images: item.images.map(img => ({ ...img, primary: imageId === img.id })) } : item)
        }
      });
    })
    .catch(errHandler);
}
