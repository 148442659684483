import wurd from 'wurd-react';

import store from 'store';
import { getLangText } from 'utils/ui';

import ListItemCard from 'components/list-item-card';
import Title from 'components/title';
import analytics from 'utils/analytics';


const cms = wurd.block('valetOrder.plan');

export default function ValetOrderPlan({ nextStep, inputProps, formValue }) {
  const settings = store.get('settings');
  const { invoicePeriod } = settings;

  function select(plan) {
    const { market } = Object.fromEntries(new URLSearchParams(window.location.search));
    analytics.selectItem({
      item_list_name: 'Plan',
      items: [{
        item_id: plan.id,
        item_name: getLangText(plan.title, ''),
        item_brand: market,
        price: plan.price,
        index: settings.plans.findIndex(p => p.id === plan.id),
      }]
    });

    nextStep({ plan });
  }

  return (
    <>
      <Title cms={cms.block('title')} className="mt-5" />

      <ul className="list-unstyled d-flex flex-column gap-3 mx-auto mb-4" style={{ maxWidth: 560 }}>
        {settings.plans.sort((a, b) => ((a.order || 0) - (b.order || 0)) || (a.price - b.price)).map(plan => {
          return (
            <li key={plan.id}>
              <ListItemCard image={plan.image} className="position-relative">
                <div className="d-flex justify-content-between flex-grow-1">
                  <div className="mw-0">
                    <h3 className="text-truncate ls-2">{getLangText(plan.title)}</h3>
                    <h4 className="text-muted fw-light fs-5">{getLangText(plan.info)}</h4>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <span className="fs-4 fw-600 ls-1 text-nowrap">{getLangText(plan.displayPrice)}</span>
                    <small className="text-nowrap text-muted fw-light"><cms.Text id={`per_${invoicePeriod}`} /></small>
                  </div>
                </div>
                <button
                  onClick={() => select(plan.name)}
                  className={`align-self-end btn text-nowrap btn-primary ${!wurd.editMode ?  'stretched-link' : ''}`}
                >
                  <span className="d-inline-flex align-items-center"><cms.Text id="submitText" /><i className="far fa-arrow-right ms-2" /></span>
                </button>
              </ListItemCard>
            </li>
          );
        })}
      </ul>
    </>
  )
}
