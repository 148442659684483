import styled from '@emotion/styled';


const Wrapper = styled.div({
  '.accordion-button': {
    '@media only screen and (max-width: 768px)': {
      padding: '.5rem .75rem'
    },
    '::after': {
      fontSize: '1.5rem'
    }
  },

})

export default ({ id, variant = 'primary', title, children, ...props }) => (
  <Wrapper className="accordion accordion-flush border border-info border-2 rounded" id={id} {...props}>
    <div className="accordion-item">
      <h2 className="accordion-header" id={`${id}-title`}>
        <button
          type="button"
          className={`accordion-button collapsed d-flex bg-${variant}`}
          data-bs-toggle="collapse"
          data-bs-target={`#${id}-details`}
          aria-expanded="false"
          aria-controls={`${id}-details`}
        >
          {title}
        </button>
      </h2>
      <div id={`${id}-details`} className="accordion-collapse collapse" aria-labelledby={`${id}-title`} data-bs-parent={`#${id}`}>
        {children}
      </div>
    </div>
  </Wrapper>
)