import Button from './button';
import styled from '@emotion/styled';


const Wrapper = styled.div({
  maxWidth: 560,
  marginBottom: '1rem',
});

export default (props) => (
  <Wrapper className="mx-auto">
    <Button className="mt-4" {...props} />
  </Wrapper>
);