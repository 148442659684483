import Loader from 'components/loader';
import Paper from 'components/paper';
import ListLoadMoreButton from 'components/list-load-more-button';
import UnitTypeCard from './unit-type-card';


export default function SiteDetail_UnitTypeList({
  cms,
  site,
  unitTypes,
  infiniteQuery,
}) {
  if (infiniteQuery.isLoading) return <Loader />;

  return (
    <>
      {unitTypes?.length > 0 ? (
        <>
          <ul className="list-unstyled d-flex flex-column gap-3 mt-2">
            {unitTypes.map(unitType => (
              <li key={unitType.id}>
                <UnitTypeCard unitType={unitType} site={site} />
              </li>
            ))}
          </ul>

          <ListLoadMoreButton
            infiniteQuery={infiniteQuery}
          />
        </>
      ) : (
        <Paper padded className="mt-5">
          <cms.Markdown id="empty.info" type="div" />
        </Paper>
      )}
    </>
  );
}
