import React from 'react';
import ItemCard from './card';
import ItemRow from './row';


export default function ValetItemList({
  items = [],
  selectedItems,
  viewMode = localStorage.getItem('sg-layout') || 'grid', // 'grid'|'list'
  ...rest // toggleModal, toggleSelected, buttonIcon, cms
}) {
  if (viewMode === 'list') {
    return (
      <ul className="list-unstyled d-flex flex-column" style={{ gap: '.5rem' }}>
        {items.map(item => (
          <ItemRow
            key={item.id}
            item={item}
            isSelected={selectedItems?.includes(item.id)}
            {...rest}
          />
        ))}
      </ul>
    );
  }

  return (
    <ul className="list-unstyled row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
      {items.map(item => (
        <ItemCard
          key={item.id}
          item={item}
          isSelected={selectedItems?.includes(item.id)}
          {...rest}
        />
      ))}
    </ul>
  );
}
