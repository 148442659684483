import styled from '@emotion/styled';
import Tooltip from './tooltip';
import { getLangText } from 'utils/ui';


const Ul = styled.ul({
  'li': {
    padding: '6px 18px 6px 0',
    color: 'var(--bs-gray)',
  },

  'li.sg-pill': {
    color: 'var(--brand-dark)',
    backgroundColor: 'var(--brand-light)',//'#F4F8FF',
    padding: '6px 14px',
    borderRadius: 4,
    maxWidth: 200,
    fontSize: '.875rem',
    marginBottom: '.375rem',
    '&:not(:last-child)': {
      marginRight: '.375rem'
    },
  },
});


export default function ({
  tags,
  className = '',
  iconsOnly = false,
  ...rest
}) {
  return (
    <Ul
      className={`list-inline d-inline-flex flex-wrap align-middle ${className}`}
      {...rest}
    >
      {tags.map(tag => (
        <li key={tag.id} className={`text-truncate ${!iconsOnly ? 'sg-pill' : ''}`} aria-label={getLangText(tag.title)}>
          {iconsOnly
            ? (
              <Tooltip id={`feature-${tag.id}`} title={getLangText(tag.title)}>
                <i className={`${tag.icon} far`} />
              </Tooltip>
            ) : (
              <>
                <i className={`${tag.icon} far`} />
                <span className="text-truncate ms-1">{getLangText(tag.title)}</span>
              </>
            )
          }
        </li>
      ))}
    </Ul>
  );
}
