import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import store from 'store';
import { getLangText } from 'utils/ui';
import useScrollRef from 'utils/useScrollRef';


const cms = wurd.block('unitOrder.summary');

export const Container = styled.div({
  backgroundColor: '#fffc',
  borderTop: 'var(--border-light)',
  borderBottom: 'var(--border-light)',

  ol: {
    listStyle: 'none',
    counterReset: 'order-progress',
    display: 'flex',
    gap: '.5rem',
    overflowX: 'auto',
    padding: 0,
    minHeight: 56,
    whiteSpace: 'nowrap',
    fontSize: '.875rem',
    '--icon-size': '1.5rem',

    '@media only screen and (min-width: 768px)': {
      justifyContent: 'space-evenly',
      '--icon-size': '1.75rem',
      fontSize: '1rem',
      gap: '1rem',
    }
  },

  small: {
    fontSize: '.75rem'
  },

  'li': {
    counterIncrement: 'order-progress',
    display: 'flex',
    alignItems: 'center',
    // flex: '1 0 150px',
    // justifyContent: 'center',
    padding: '.375rem',
    color: 'var(--bs-gray)',

    a: {
      color: 'var(--bs-gray)',
      '&:hover': {
        filter: 'brightness(1.25)'
      }
    },

    '&.active': {
      color: 'var(--brand)',

      'a': {
        color: 'var(--brand)',
      }
    },

    '&::before': {
      content: 'counter(order-progress)',
      width: 'var(--icon-size)',
      minWidth: 'var(--icon-size)',
      height: 'var(--icon-size)',
      borderRadius: '50%',
      display: 'inline-flex',
      lineHeight: 'var(--icon-size)',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid rgba(var(--bs-gray-rgb), .5)',
      marginRight: '.5rem',
      filter: 'opacity(.75)',
      '@media only screen and (min-width: 768px)': {
        marginRight: '.75rem',
      }
    },

    '&:has(~ li.active)::before': {
      backgroundColor: 'rgba(var(--bs-gray-rgb), .75)',
      color: 'var(--brand-text)',
      content: '"\\f00c"',
      fontFamily: '"Font Awesome 5 Pro"',
      fontWeight: 400,
    },

    '&.active::before': {
      backgroundColor: 'var(--brand)',
      borderColor: 'var(--brand)',
      color: 'var(--brand-text)',
    }
  },
});

export default function UnitProgress({ site, unitType, step }) {
  const scrollRef = useScrollRef('.active', [step]);

  return (
    <Container ref={scrollRef}>
      <ol className="container">
        <li className={!site ? 'active' : null}>
          {site
            ? (
              <Link to="/sites" className="text-decoration-none d-grid">
                <small style={{ opacity: .7 }}><cms.Text id="site.done" /></small>
                <span>{getLangText(site.title)}</span>
              </Link>
            ) : <cms.Text id="site.todo" />}
        </li>
        <li className={site && !unitType ? 'active' : null}>
          {unitType
            ? (
              <Link to={`/sites/${site.code}`} className="text-decoration-none d-grid">
                <small className="" style={{ opacity: .7 }}><cms.Text id="unit.done" /></small>
                <span className="">{getLangText(unitType.title)}</span>
              </Link>
            ) : <cms.Text id="unit.todo" />}
        </li>
        <li className={unitType ? 'active' : null}>
          {unitType
            ? (
              <Link to={`/sites/${site.code}/${unitType.code}`} className="text-decoration-none d-grid">
                <cms.Text id="finalize" />
              </Link>
            ) : <cms.Text id="finalize" />}
        </li>
      </ol>
    </Container>
  );
}
