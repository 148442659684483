import { useNavigate, useParams } from 'react-router-dom';
import wurd from 'wurd-react';
import { useMutation } from 'react-query';
import * as actions from 'actions';
import { hasBillingMethod } from '../utils/user';
import useSearchData from 'utils/useSearchData';
import useCache from 'utils/useCache';
import store from '../store';
import BoxesStep from '../components/valet-order-steps/boxes';
import ProductsStep from '../components/valet-order-steps/products';
import AreaStep from '../components/valet-order-steps/area';
import TimeStep from '../components/valet-order-steps/time';
import ItemsStep from '../components/valet-order-steps/items';
import BillingStep from '../components/valet-order-steps/billing';
import Loader from 'components/loader';
import ErrMsg from 'components/err-msg';
import useForm from 'utils/useForm';
import { formatOrder, hasOrderedItems } from 'utils/valet';


const cms = wurd.block('valetOrder');

const stepComponents = {
  boxes: BoxesStep,
  products: ProductsStep,
  area: AreaStep,
  time: TimeStep,
  'collect-items': props => <ItemsStep {...props} type="collect" />,
  'deliver-items': props => <ItemsStep {...props} type="deliver" />,
  billing: BillingStep,
}

/*
Valet order edit
*/
function getStep(data) {
  const { settings, user } = store.get();

  if (settings.items?.length > 0 && data.boxes === undefined) return 'boxes';

  if (settings.products?.length > 0 && data.products === undefined) return 'products';

  if (user?.items.filter(item => item.state !== 'user').length > 0 && data.deliverItems === undefined) return 'deliver-items';

  if (user?.items.filter(item => item.state === 'user').length > 0 && data.collectItems === undefined) return 'collect-items';

  if (!data.date || !data.timeslot) return 'time';

  if (hasOrderedItems({ boxCounts: data.boxes }) && data.collect === 'later') {
    if (data.date >= data.collectDate || !data.collectDate || !data.collectTimeslot) {
      return 'time'; //The collection date must be set after the delivery date;
    }
  }

  if (!user || data.area === undefined || !(user.phone || data.phone) || !(user.address || data.address)) return 'area';

  if (!hasBillingMethod(settings, user) || data.session_id/* stripe-checkout callback */) return 'billing';
}

export default function () {
  const { user, settings } = store.get();
  const params = useParams();
  const [data, setData] = useSearchData();
  const navigate = useNavigate();
  const order = user?.valetOrders.find(o => o.id === params.id);

  const getItemSid = id => user.items.find(item => item.id === id)?.sid; // sid look better in urls

  const cache = useCache({
    products: Object.fromEntries(Object.entries(order?.productCounts || {}).map(([id, quantity]) => [settings.products.find(p => p.id === id)?.slug, quantity])),
    collectItems: user && order?.collectItems?.map(getItemSid),
    deliverItems: user && order?.deliverItems?.map(getItemSid),
    date: order?.date,
    timeslot: order?.timeslot,
    collect: order?.collect,
    collectDate: order?.collectDate,
    collectTimeslot: order?.collectTimeslot,
  });

  const step = getStep(data);

  const combinedData = {
    ...cache.data,
    ...data,
    id: order?.id,
    boxes: order?.boxCounts,
  };

  const formProps = useForm({
    initialValue: combinedData,
    cms: cms.block(step),
  });

  const orderMutation = useMutation(
    data => actions.valetOrders.update(order.id, formatOrder(data))
      .then(async order => {
        await actions.user.fetch();

        navigate(`/account/items#order_${order.sid.toUpperCase()}`);
      })
      .finally(() => {
        cache.del();
      })
  );

  if (!step && orderMutation.isIdle) {
    orderMutation.mutate(formProps.formValue);
  }

  const StepComponent = stepComponents[step];

  const nextStep = (d) => {
    cache.set({ ...formProps.formValue });
    setData({ ...data, ...d });
  };

  if (!order) return <cms.Markdown id="notFound" />;

  return (
    <div className="container">
      <div className="alert alert-info">
        <cms.Markdown id="info" vars={{ sid: order.sid.toUpperCase() }} />
      </div>

      {orderMutation.error && <ErrMsg err={orderMutation.error} />}

      {orderMutation.isLoading && <Loader />}

      {StepComponent && (
        <StepComponent
          {...combinedData}
          boxes={{ ...order.boxCounts/*, ...order.bulkyCounts */ }}
          data={data}
          {...formProps}
          order={order}
          nextStep={nextStep}
        />
      )}
    </div>
  );
}
