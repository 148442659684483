import { isValidElement } from 'react';
import { marked } from 'marked';
import styled from '@emotion/styled';


const Container = styled.div({
  'h1, h2, h3, h4': {
    fontSize: '1em',
    fontWeight: 600,
  },
  table: {
    fontWeight: 400,
    width: '100%',
  },
  'tr > :not(:first-child)': {
    textAlign: 'center'
  },
  'th:empty': {
    padding: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
  },
});


export default function Markdown({
  inline,
  children,
  as: Wrapper = inline ? 'span' : Container,
  ...props
}) {
  if (!children || isValidElement(children)) return children;
  return (
    <Wrapper
      {...props}
      dangerouslySetInnerHTML={{
        __html: inline
          ? marked.parseInline(children)
          : marked(children.replace(/<style/g, `<style nonce="${document.getElementById('app').dataset.styleNonce}"`))
      }}
    />
  );
}
