import { useRef, useState, cloneElement } from 'react';
import BsTooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';

export default function Tooltip({ id, title, children, ...props }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  function toggle() {
    if (!show) {
      document.getElementById('app').addEventListener('click', function hideOnClickOutside(e) {
        const tooltip = document.getElementById(id);
        if (!target.current || !tooltip) { // was unmounted, remove listener
          document.getElementById('app').removeEventListener('click', hideOnClickOutside);
          return;
        }
        if (target.current.contains(e.target)) { // ignore click on target already handled
          return;
        }
        if (!tooltip.contains(e.target)) {
          setShow(false);
        }
      }, { once: true });
    }
    setShow(!show);
  }

  return (
    <>
      {cloneElement(children, { ref: target, title, onClick: toggle })}
      <Overlay target={target.current} show={show}>
        {(props) => (
          <BsTooltip id={id} {...props}>{title}</BsTooltip>
        )}
      </Overlay>
    </>
  );
}
