import wurd from 'wurd-react';
import { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Modal from 'bootstrap/js/dist/modal';
import * as actions from '../actions';
import useForm from 'utils/useForm';
import useSearchData from 'utils/useSearchData';
import Field from 'components/field';
import Paper from 'components/paper';
import Title from 'components/title';
import Button from 'components/button';
import ErrMsg from 'components/err-msg';
import GoogleButton from 'components/google-button';
import Captcha from 'plugins/captcha';

const cms = wurd.block('login');

const Container = styled('div')({
  maxWidth: 460,
  padding: 15,
  margin: 'auto',
});

export default function Login({ onSuccess }) {
  const navigate = useNavigate();
  const [data, setData] = useSearchData();
  const passwordRef = useRef();
  const { submit, fieldProps, loading, err, setErr } = useForm({
    cms,
    onSubmit: actions.user.signin,
    onSuccess: () => {
      // close modal https://github.com/twbs/bootstrap/issues/33727#issuecomment-825865021
      const modalEl = document.getElementById('loginModal');
      const modal = Modal.getInstance(modalEl);
      if (modal) {
        modal.hide();
        modalEl.addEventListener('hidden.bs.modal', () => {
          modal.dispose();
        }, { once: true });
      }
      if (onSuccess === undefined) navigate('/');
    }
  });
  useEffect(() => {
    if (data.error) {
      setErr({ message: data.error });
      setData({});
    }
  }, []);

  return (
    <Container>
      <Title cms={cms.block('title')} className="my-5" />

      <Paper padded>
        <form onSubmit={submit}>
          <div className="d-flex flex-column gap-2">
            <Captcha />
            <Field
              {...fieldProps('email', '')}
              required
              autoComplete="username"
              autoFocus
            />
            <Field
              {...fieldProps('password', '')}
              required
              type="password"
              autoComplete="current-password"
            />
          </div>

          {err && <ErrMsg err={err} className="mt-2" />}

          <Button type="submit" className="w-100 text-center mt-5 mb-2" loading={loading}><cms.Text id="submitText" /></Button>
          <div className="text-center">
            <Link to="/account/forgot-password" className="fs-7 text-primary btn btn-link btn-sm text-start"><cms.Text id="forgotPassword" /></Link>
          </div>
        </form>

        <GoogleButton redirectUrl={location.origin + '/account'} />
      </Paper>
    </Container>
  )
}
