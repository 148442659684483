import moment from 'moment';
import store from '../store';

/**
 * Get timeslot start/end times based on locale
 * @param {String} timeslot       E.g. '1100-1300'
 * @return {String}               E.g. '11am to 1pm'
 */
export function getTimeslot(timeslot = '', timeFormat = 'HH:mm') {
  const [start, end = ''] = timeslot?.split('-') || [],
    startHours = start.slice(0, 2),
    startMins = start.slice(2, 4),
    endHours = end.slice(0, 2),
    endMins = end.slice(2, 4);

  const startStr = moment.utc().startOf('day')
    .add(startHours, 'hours')
    .add(startMins, 'minutes')
    .format(timeFormat || 'HH:mm');

  const endStr = moment.utc().startOf('day')
    .add(endHours, 'hours')
    .add(endMins, 'minutes')
    .format(timeFormat || 'HH:mm');

  return { start: startStr, end: endStr };
}

export function hasOrderedItems(valetOrder) {
  return Object.entries(valetOrder?.boxCounts || {}).some(([k, v]) => !k.startsWith('bulky') && v > 0);
}

export function hasBulkyItems(valetOrder) {
  return Object.values(valetOrder?.bulkyCounts || {}).some(count => count > 0);
}

export function hasOrderedProducts(valetOrder) {
  return Object.values(valetOrder?.productCounts || {}).some(count => count > 0);
}

export function isDelivery(valetOrder) {
  return valetOrder.deliverItems?.length > 0 || hasOrderedItems(valetOrder);
}

export function isCollection(valetOrder) {
  return valetOrder.collectItems?.length > 0 || hasBulkyItems(valetOrder);
}

/**
 * Checks if the valet order can still be rescheduled by the customer
 */
export function isWithinRescheduleWindow(valetOrder) {
  const maxHours = 24;

  const orderDate = moment(valetOrder.date);
  const now = moment();

  const hoursDifference = orderDate.diff(now, 'hours');

  return hoursDifference > maxHours;
}

export function canReschedule(valetOrder) {
  return valetOrder.state === 'submitted' && isWithinRescheduleWindow(valetOrder);
}

export function getType(valetOrder) {
  const isDel = isDelivery(valetOrder);
  const isCol = isCollection(valetOrder);

  if (isDel && isCol && valetOrder.collect === 'immediately') {
    return 'both';
  }
  else if (isDel) {
    return 'delivery';
  }
  else if (isCol) {
    return 'collection';
  }
  else {
    return 'generic';
  }
}

export function formatOrder(data) {
  const { settings, user } = store.get();

  const getItemId = sid => user.items.find(item => item.sid === sid || item.id === sid)?.id;

  return {
    planId: data.plan && settings.plans.find(o => o.name === data.plan)?.id,
    boxCounts: Object.fromEntries(Object.entries(data.boxes || {}).filter(([k]) => !k.startsWith('bulky'))),
    bulkyCounts: Object.fromEntries(Object.entries(data.boxes || {}).filter(([k]) => k.startsWith('bulky'))),
    productCounts: Object.fromEntries(Object.entries(data.products || {}).map(([k, v]) => [settings.products.find(o => o.slug === k)?.id, v])),
    address: data.address || user?.address,
    phone: data.phone || user?.phone,
    area: data.area || user?.area,
    altPhone: data.altPhone,
    instructions: data.instructions,
    date: data.date,
    timeslot: data.timeslot,
    collect: data.collect,
    collectDate: data.collectDate,
    collectTimeslot: data.collectTimeslot,
    deliverItems: (typeof data.deliverItems === 'string' ? data.deliverItems.split(',') : data.deliverItems)?.map(getItemId).filter(Boolean),
    collectItems: (typeof data.collectItems === 'string' ? data.collectItems.split(',') : data.collectItems)?.map(getItemId).filter(Boolean),
    promoCode: data.code,
  };
}

export function getTotals(order) {
  const { settings, user } = store.get();

  const planId = order.planId || user?.planId;
  const plan = planId && settings.plans.find(o => o.id === planId);

  let subtotal = plan ? plan.price : 0;

  for (const [type, count] of Object.entries(order.boxCounts || {})) {
    subtotal += settings.items.find(o => o.type === type)?.price * count;
  }
  for (const [type, count] of Object.entries(order.bulkyCounts || {})) {
    subtotal += settings.items.find(o => o.type === type)?.price * count;
  }
  for (const [productId, count] of Object.entries(order.productCounts || {})) {
    subtotal += settings.products.find(o => o.id === productId)?.price * count;
  }

  const selectedArea = order.area && settings.areas.find(area => area.id === order.area);
  const areaSurcharge = selectedArea?.surcharge || 0;
  subtotal += areaSurcharge;

  const taxAmount = subtotal * ((settings.tax?.percent || 0) / 100);
  const total = subtotal + taxAmount;

  return { subtotal, tax: taxAmount, total, plan, areaSurcharge };
}
