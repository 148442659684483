import styled from '@emotion/styled';
import wurd from 'wurd-react';

import { getItemTitle, getOrder, getImage } from 'utils/item';

import SgButton from 'components/button';
import Paper from 'components/paper';

import { shadowGradient, selectButton } from './card';


const defaultCms = wurd.block('my-items.card');

const ImgWrapper = styled.div({
  ...shadowGradient,
  width: 90,
  img: {
    aspectRatio: '4 / 3',
  }
});

const Button = styled(SgButton)({
  ...selectButton,
  borderColor: 'var(--bs-secondary)',
});


export default function ItemRow({
  item,
  onSelect,
  isSelected,
  onDelete,
  toggleModal,
  toggleSelected,
  cms = defaultCms,
  disabled = order => !!order,
}) {
  const order = getOrder(item);
  const itemImage = getImage(item);
  const btn = cms.block(item.state);

  return (
    <li>
      <Paper
        className="d-flex gap-1 overflow-hidden fs-5"
        onClick={toggleModal ? () => toggleModal(item.id) : null}
      >
        <ImgWrapper>
          <img src={itemImage} alt="img" className="cover h-100 w-100" />
        </ImgWrapper>
        <div className="flex-1 d-flex align-items-center flex-wrap" style={{ minWidth: 0 }}>
          <div className="p-1 p-sm-2 flex-1" style={{ minWidth: 0 }}>
            <strong className="fw-600 d-block text-truncate">{getItemTitle(item)}</strong>
            <div className="text-muted fw-light fs-6 text-truncate">{item.desc}</div>
          </div>
          <div className="p-1 p-sm-2 d-flex align-items-center gap-1 gap-md-2 mw-100" style={{ minWidth: 0 }}>
            <small className="text-uppercase user-select-all text-muted badge fw-normal" style={{ background: 'var(--brand-light)' }}>{item.sid}</small>
            <Button
              type="button"
              className={`text-muted mw-100 ${isSelected ? 'selected' : ''}`}
              disabled={disabled(order)}
              onClick={e => { e.stopPropagation(); toggleSelected(item, !isSelected); }}
            >
              <i className="fal fa-truck order-2 order-md-0" />
              {order && disabled(order)
                ? (
                  <btn.Text id="scheduled" className="d-none d-md-inline" vars={{ sid: order.sid.toUpperCase() }} />
                ) : isSelected
                  ? (
                    <><btn.Text id="selected" className="d-none d-md-inline" /><i className="fas fa-check-circle" /></>
                  ) : (
                    <><btn.Text id="unselected" className="d-none d-md-inline" /><i className="fal fa-circle" style={{ filter: 'opacity(.5)' }} /></>
                  )
              }
            </Button>
          </div>
        </div>
      </Paper>
    </li>
  );
}
