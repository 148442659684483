import wurd, { WurdMarkdown } from 'wurd-react';
import Markdown from 'components/markdown';


export function getErrId(err) {
  // wurd key returned as error message e.g. item.cannotUnassignBulky
  if (/^[a-z][a-zA-Z_-]+(\.[a-z][a-zA-Z_-]+)+$/.test(err.message)) return err.message;

  // message text e.g 'Unverified email'
  const errMsg = err.message.trimStart()
    .replace(/[a-f0-9]{24}/g, '') // remove object ids to make it generic (we should avoid site.code or such thing in error message)
    .replace(/[^a-zA-Z_ -]/g, '') // remove punctuation
    .replace(/ +./g, c => c[c.length - 1].toUpperCase()) // camelCase
    .replace(/^./, c => c.toLowerCase()) // lower first char
    .trimEnd()
    .slice(0, 40);

  return `common.errors.${errMsg}`;
}

export function getErrMsg(err) {
  if (typeof err.message !== 'string') return err.message; // err.message is already a WurdText

  const errKey = getErrId(err);

  return wurd.editMode || wurd.text(errKey) ? <WurdMarkdown id={errKey} /> : <Markdown>{err.message}</Markdown>;
}

export default function ErrMsgAlert({ err, type = 'danger', className, ...props }) {
  return (
    <div className={`alert alert-${type} ${className || ''}`} role="alert" {...props}>
      {getErrMsg(err)}
    </div>
  )
}