import { useEffect, useRef, useState } from 'react';
import wurd from 'wurd-react';
import styled from '@emotion/styled';
import { Global } from '@emotion/react';
import { useQuery } from 'react-query';
import * as actions from 'actions';
import useResize from 'utils/useResize';
import useForm from 'utils/useForm';

import Button from 'components/step-button';
import Title from 'components/title';
import PdfPreview from 'components/pdf-preview';


const cms = wurd.block('unitOrder.order');

const Container = styled.div(`
  font-family: 'Noto Sans HK', sans-serif;
  line-height: 1.375;
  font-size: .875rem;
  background-color: #fff;
  padding: 0.01rem 1.25rem;
  position: relative;

  * { box-sizing: border-box; }
  b, strong { font-weight: bold; }
  u, ins { text-decoration: underline; cursor: initial; }
  s, del { text-decoration: line-through; }
  i, em { font-style: italic; }
  h1, h2, h3, h4, h5, h6 { font-weight: 600; margin-bottom: 0; margin-top: 1em; line-height: 1.5; }
  h1 { font-size: 2.5em; }
  h2 { font-size: 2em; }
  h3 { font-size: 1.5em; }
  h4 { font-size: 1.2em; }
  h5 { font-size: 1em; }
  h6 { font-size: 1em; }
  a { color: blue; text-decoration: underline; line-break: anywhere; }
  p { margin-top: 0; margin-bottom: .5rem; }
  ul { margin-bottom: 5px; margin-left: 5px; list-style: square; }
  table > thead > tr > th:empty { display: none; }
  table { margin-bottom: 5px; border-collapse: collapse; width: 100%; font-size: .875rem; break-inside: auto; }
  td:nth-of-type(1) { width: var(--table-first-col, 35%); }
  td, th { border: 1px solid #000a; padding: 4px 8px; }
  th:empty { display: none; }
  th { font-weight: bold; background-color: #EEEEEE; }
  tr { break-inside: avoid; break-after: auto; }
  thead, tfoot { display: table-row-group; }
  img { max-width: 100%; }
`);

const Signature = styled.div({
  position: 'relative',
  canvas: {
    width: '100%',
    backgroundColor: '#fff',
  },
  button: {
    position: 'absolute',
    right: 2,
    bottom: 4,
  },
});


export default function UnitOrderEsignStep({
  site,
  order,
  nextStep,
}) {
  const signaturePad = useRef({ clear() { } });
  const canvasRef = useRef();
  // const urlRef = useRef();
  const [agreementFile, setAgreementFile] = useState();
  const [isEmpty, setEmpty] = useState(true);
  const pdfBufQuery = useQuery('pdf-buf', () => agreementFile.arrayBuffer(), { enabled: !!agreementFile?.size });

  useEffect(() => {
    if (!site.enableMoveInAgreement) return;

    canvasRef.current.width = canvasRef.current.offsetWidth;

    import('signature_pad')
      .then(({ default: SignaturePad }) => {
        signaturePad.current = new SignaturePad(canvasRef.current, {
          // It's Necessary to use an opaque color when saving image as JPEG;
          // this option can be omitted if only saving as PNG or SVG
          backgroundColor: 'rgb(255, 255, 255)'
        });

        signaturePad.current.addEventListener('endStroke', () => {
          setEmpty(signaturePad.current.isEmpty());
        });

        return actions.unitOrders.previewAgreement(order.id)
          .then(async r => {
            if (r.headers.get('content-type') === 'application/pdf') {
              setAgreementFile(await r.blob());
            } else {
              setAgreementFile(await r.json());
            }
          });
      });
  }, []);

  useResize(() => {
    canvasRef.current.width = canvasRef.current.offsetWidth;
    reset(); // anyway the canvas clears already on resize, this ensure the button className updates
  });

  function reset() {
    signaturePad.current.clear();
    setEmpty(true);
  }

  const { submit, isLoading } = useForm({
    onSubmit: async function () {
      await nextStep({
        signatureUrl: site.enableMoveInAgreement ? signaturePad.current?.toDataURL() : undefined,
      });
    },
  });

  if (!site.enableMoveInAgreement) {
    return <div>E-sign not enabled</div>;
  }

  return (
    <>
      <Title cms={cms.block('title')} className="mt-5" />

      {agreementFile?.html && (
        <>
          <Global styles={`@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@400;700&display=swap');`} />
          <Container dangerouslySetInnerHTML={{ __html: agreementFile.html.replace(/<style/g, `<style nonce="${document.getElementById('app').dataset.styleNonce}"`) }} />
        </>
      )}

      {pdfBufQuery.isLoading ? <i className="loading fa fa-spinner fa-spin" /> : pdfBufQuery.data && <PdfPreview arrayBuffer={pdfBufQuery.data} />}

      {agreementFile?.size && (
        <a
          href={URL.createObjectURL(agreementFile)}
          target="_blank"
          className="mt-1 btn btn-link"
        >
          <cms.Text id="openPdf" type="strong" />
        </a>
      )}

      {!agreementFile && <i className="far fa-spinner fa-spin" />}

      <Signature className="mt-4">
        <Title as="h5" cms={cms.block('sign')} />
        <canvas ref={canvasRef} width={560} height={200} />

        <button onClick={reset} className="btn btn-sm btn-gray-bg border border-light text-muted ms-1" disabled={isEmpty}>
          <cms.Text id="clear" />
        </button>
      </Signature>

      <Button type="button" onClick={submit} disabled={isEmpty} loading={isLoading}>
        <cms.Text id={isEmpty ? 'emptyText' : 'submitText'} />
      </Button>
    </>
  );
}
