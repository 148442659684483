import store from 'store';


export default async (data) => {
  const settings = store.get('settings');

  if (settings.captcha === 'recaptcha') {
    await window.grecaptcha.execute();
    data.recaptchaResponse = window.grecaptcha.getResponse();
  }
}