import styled from '@emotion/styled';
import wurd, { WurdText } from 'wurd-react';

import store from 'store';
import { getLangText, getPrice, getTypePrice } from 'utils/ui';

import Paper from 'components/paper';
import Markdown from 'components/markdown';
import FeatureList from 'components/feature-list';
import Accordion from 'components/accordion';
import useMobile from 'utils/useMobile';


const cms = wurd.block('siteDetail');

const Wrapper = styled(Paper)({
  border: 0,

  '.sgc-image': {
    borderRadius: 4,
    maxHeight: 400,
    aspectRatio: '7 / 4',
    width: '100%',
    objectFit: 'contain',
  },
});


export default function UnitTypeDetail_UnitTypeCard({
  site,
  unitType,
}) {
  const { invoicePeriod } = store.get('settings');
  const isMobile = useMobile();

  const content = (
    <Wrapper className="card">
      <div className="card-body">
        <img className={`sgc-image mb-4 ${unitType.imageFit || ''}`} src={unitType.image} alt={site.code} />

        {/*<small className="text-muted"><cms.Text id="selected" /></small>*/}

        <div className="d-flex gap-2">
          <div className="flex-1 mw-0">
            <h2 className="text-truncate">{getLangText(unitType.title)}</h2>
            <p className="fs-5">{getLangText(unitType.subtitle)}</p>
          </div>
          <div className="text-end">
            <h2>
              <span className="fs-2 fw-600 ls-1">{getTypePrice(unitType)}</span>
              <small className="fs-5 fw-500 text-nowrap text-muted"><cms.Text id={`per_${invoicePeriod}`} /></small>
            </h2>
            <span className="fs-6">{getPrice(unitType.deposit_display || unitType.deposit)}<cms.Text id="deposit"className="text-gray" /></span>
          </div>
        </div>

        {getLangText(unitType.info) && (
          <Markdown className="markdown text-muted mt-3 fs-6 fw-light">{getLangText(unitType.info)}</Markdown>
        )}

        {unitType.tags.length > 0 && (
          <>
            <h4 className="fs-6 fw-600"><cms.Text id="features" /></h4>
            <FeatureList tags={unitType.tags} />
          </>
        )}
      </div>
    </Wrapper>
  );

  return isMobile
    ? (
      <Accordion
        id="unitDetail"
        variant="white"
        title={(
          <span className="d-flex flex-1">
            <span className="d-flex flex-column flex-1 me-2">
              <small className="text-gray me-1" style={{ opacity: .7 }}><WurdText id="unitOrder.summary.unit.done" /></small>
              <span>{getLangText(unitType.title)}</span>
            </span>
            <span className="d-flex align-items-center me-2">
              {getTypePrice(unitType)}<cms.Text id={`per_${invoicePeriod}`} type="small" className="text-gray fs-7" />
            </span>
          </span>
        )}
      >
        {content}
      </Accordion>
    ) : content;
}
