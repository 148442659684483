import { useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import mergeWith from 'lodash/mergeWith';


export default function useForm({
  initialValue,
  cms, // not required for inputProps, required for fieldProps()
  onSubmit = async data => data,
  onSuccess = data => data,
  onError,
  err: initialErr,
}) {
  const [data, setData] = useState({});
  const [err, setErr] = useState(initialErr);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null); // null|'loading'|'success'|'error'

  const formData = {};
  for (const [k, v] of Object.entries(initialValue || {})) {
    set(formData, k, v); // support dot notation
  }
  mergeWith(formData, data, (a, b) => Array.isArray(b) ? b : undefined);

  function getInputProps(name, defaultValue) {
    const value = get(formData, name) ?? defaultValue;
    return {
      name,
      ...{ [typeof value === 'boolean' ? 'checked' : 'value']: value },
      onChange: (event) => {
        const value = !event.target
          ? event
          : event.target.multiple
            ? [...event.target.selectedOptions].map(o => o.value)
            : event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value;

        setData(data => {
          return set({ ...data }, name, value);
        });
      },
    };
  }

  function getFieldProps(name, defaultValue) {
    const sname = name.startsWith('customFields.') ? name.slice(13) : null;
    return {
      ...getInputProps(name, defaultValue),
      error: err?.errors?.[name] || err?.errors?.[sname],
      cms: cms.block(name),
    }
  }

  async function handleSubmit(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setStatus('loading');
    setErr(null);

    return onSubmit(formData, data)
      .then(onSuccess)
      .then((data) => {
        setStatus('success');
        // setData({});
        return data;
      })
      .catch(err => {
        setErr(err);
        setStatus('error');
        if (onError) onError(err);
        throw err;
      });
  }

  function reset() {
    setData({});
    setStatus(null);
    setErr(initialErr);
  }

  const dirty = Object.entries(data).some(([k, v]) => v != null && (initialValue?.[k] == undefined || v !== initialValue[k]));

  return {
    inputProps: getInputProps,
    fieldProps: getFieldProps,
    submit: handleSubmit,
    formValue: formData,
    // setData: patch => setData({ ...data, ...patch }),
    err,
    setErr,
    loading: status === 'loading', // deprecated, use isLoading
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    isError: status === 'error',
    status,
    dirty,
    reset,
  };
}
