import wurd from 'wurd-react';
import store from 'store';
import Counter from 'components/counter';
import Button from 'components/step-button';
import Title from 'components/title';
import analytics from 'utils/analytics';
import { getLangText } from 'utils/ui';


const cms = wurd.block('valetOrder.products');

export default function ({ nextStep, inputProps, formValue }) {
  const settings = store.get('settings');

  function submit(e) {
    e.preventDefault();

    if (Object.keys(formValue.products || {}).length > 0) {
      const { market } = Object.fromEntries(new URLSearchParams(window.location.search));
      analytics.selectItem({
        item_list_name: 'Products',
        items: settings.products.map((p, index) => {
          const quantity = formValue.products[p.id];
          if (!quantity) return null;
          return {
            item_id: p.id,
            item_name: getLangText(p.title, ''),
            item_brand: market,
            price: p.price,
            quantity,
            index,
          }
        }).filter(Boolean),
      });
    }

    nextStep({ products: formValue.products || {} });
  }

  return (
    <>
      <Title cms={cms.block('title')} className="mt-5" />

      <cms.Markdown id="info" className="text-muted mt-3" />

      <form onSubmit={submit} className="mt-5">
        <ul className="list-unstyled d-flex flex-column gap-3 m-auto" style={{ maxWidth: 560 }}>
          {settings.products.sort((a, b) => ((a.order || 0) - (b.order || 0)) || a.slug?.localeCompare(b.slug)).map(product => {
            if (product.quantity === 0 && product.maxQuantity === 0) return null;

            return (
              <li key={product.id}>
                <Counter
                  {...inputProps(`products.${product.slug}`, 0)}
                  item={{ ...product, code: product.slug }}
                />
              </li>
            );
          })}
        </ul>

        <Button><cms.Text id="submitText" /></Button>
      </form>
    </>
  )
}
