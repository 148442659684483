import { useQuery } from 'react-query';
import * as actions from 'actions';
import wurd from 'wurd-react';
import Collapse from 'react-bootstrap/Collapse';
import Loader from 'components/loader';
import DatePickerField from 'components/datepicker/field';
import TimeField from 'components/datepicker/time';
import Field from 'components/field';
import Button from 'components/step-button';
import Title from 'components/title';
import { getTimeslot, hasOrderedItems } from 'utils/valet';
import store from 'store';


const cms = wurd.block('valetOrder.time');

const inputStyle = { width:'1.6rem', height: '1.2rem' };

export default function ({
  nextStep, boxes = {}, fieldProps, inputProps, showCode, order, formValue
}) {
  const settings = store.get('settings');
  const { data: timeslots = [], isLoading } = useQuery('timeslots', () => actions.settings.fetchTimeslots());

  if (isLoading) return <Loader />;

  const availDates = new Map(timeslots.map(item => [item.date, item.times]));

  if (order?.date && order?.timeslot) { // make sure order's current slots are available
    availDates.set(order.date, [...new Set([...availDates.get(order.date) || [], order.timeslot])].sort());
  }
  if (order?.collectDate && order?.collectTimeslot) { // make sure order's current slots are available
    availDates.set(order.collectDate, [...new Set([...availDates.get(order.collectDate) || [], order.collectTimeslot])].sort());
  }

  const getDefautTime = d => availDates.get(d)?.length === 1 ? availDates.get(d)[0] : undefined;

  const collectProps = inputProps('collect');
  const dateProps = fieldProps('date');
  const collectDateProps = fieldProps('collectDate');
  const timeslotProps = fieldProps('timeslot');
  const collectTimeslotProps = fieldProps('collectTimeslot');

  function submit(e) {
    e.preventDefault();
    nextStep({
      date: formValue.date,
      timeslot: formValue.timeslot || getDefautTime(formValue.date),
      collect: settings.collectionWaitTime === 0 ? 'later' : formValue.collect,
      collectDate: formValue.collectDate,
      collectTimeslot: formValue.collectTimeslot || getDefautTime(formValue.collectDate),
    }, { skipCache: true });
  }

  const setDate = e => {
    dateProps.onChange(e);
    timeslotProps.onChange({
      target: {
        name: 'timeslot',
        value: formValue.timeslot || getDefautTime(e.target.value)
      }
    });
  };

  const setCollectDate = e => {
    collectDateProps.onChange(e);
    collectTimeslotProps.onChange({
      target: {
        name: 'collectTimeslot',
        value: formValue.collectTimeslot || getDefautTime(e.target.value)
      }
    });
  };

  return (
    <>
      <Title cms={cms.block('title')} className="my-5" />

      <form onSubmit={submit} className="m-auto" style={{ maxWidth: 360 }}>
        <DatePickerField
          {...dateProps}
          onChange={setDate}
          isOutsideRange={d => !availDates.has(d.format('YYYY-MM-DD'))}
          required
        />

        <TimeField {...timeslotProps} wurdKeys="label,help,placeholder,text,timeFormat">
          <select disabled={!formValue.date} required={availDates.get(formValue.date)?.length > 1} className="form-select">
            {(!formValue.date || !formValue.timeslot && availDates.get(formValue.date)?.length > 1) && <option value="">{cms.text('timeslot.placeholder')}</option>}
            {availDates.get(formValue.date)?.map(time => (
              <option key={time} value={time}>{cms.text('timeslot.text', getTimeslot(time, cms.text('timeslot.timeFormat')))}</option>
            ))}
          </select>
        </TimeField>

        {hasOrderedItems({ boxCounts: boxes }) && (
          <>
            {settings.collectionWaitTime > 0 && (
              <div className="mt-4 mx-auto d-flex flex-column fs-6" style={{ maxWidth: 320 }}>
                <cms.Text id="collect.title" className="text-muted mb-2" />
                <label className="d-flex">
                  <input
                    type="radio"
                    name="collect"
                    value="immediately"
                    checked={formValue.collect !== 'later'}
                    onChange={e => collectProps.onChange({ target: { name: 'collect', value: 'immediately' } })}
                    style={inputStyle}
                  />
                  <span className="ms-3 d-inline-flex flex-column">
                    <cms.Text id="collect.immediately.title" />
                    <cms.Markdown id="collect.immediately.desc" vars={{ waitTime: settings.collectionWaitTime }} className="text-muted fs-7 mt-1 mb-2" />
                  </span>
                </label>
                <label className="d-flex">
                  <input
                    type="radio"
                    name="collect"
                    value="later"
                    checked={formValue.collect === 'later'}
                    onChange={e => collectProps.onChange({ target: { name: 'collect', value: 'later' } })}
                    style={inputStyle}
                  />
                  <span className="ms-3 d-inline-flex flex-column">
                    <cms.Text id="collect.later.title" />
                    <cms.Markdown id="collect.later.desc" className="text-muted fs-7 mt-1 mb-2" />
                  </span>
                </label>
              </div>
            )}

            {(formValue.collect === 'later' || settings.collectionWaitTime === 0) && (
              <div className="mt-2">
                <DatePickerField
                  {...collectDateProps}
                  onChange={setCollectDate}
                  disabled={!formValue.date || (!formValue.timeslot && availDates.get(formValue.date)?.length > 1)}
                  isOutsideRange={d => { const v = d.format('YYYY-MM-DD'); return !availDates.has(v) || v <= formValue.date; }}
                  required
                />

                <TimeField {...collectTimeslotProps}>
                  <select disabled={!formValue.collectDate} required={availDates.get(formValue.collectDate)?.length > 1} className="form-select">
                    {(!formValue.collectDate || !formValue.collectTimeslot && availDates.get(formValue.collectDate)?.length > 1) && <option value="">{cms.text('timeslot.placeholder')}</option>}
                    {availDates.get(formValue.collectDate)?.map(time => (
                      <option key={time} value={time}>{cms.text('timeslot.text', getTimeslot(time, cms.text('timeslot.timeFormat')))}</option>
                    ))}
                  </select>
                </TimeField>
              </div>
            )}
          </>
        )}

        {showCode && (
          <div className="row mt-2">
            <div className="col-sm-6">
              <Field {...fieldProps('code')} />
            </div>
          </div>
        )}

        <Button><cms.Text id="submitText" /></Button>
      </form>
    </>
  );
}
