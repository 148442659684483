import { useState } from 'react';
import pick from 'lodash/pick';
import styled from '@emotion/styled';
import moment from 'moment';
import wurd from 'wurd-react';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Carousel from 'react-bootstrap/Carousel';
import Dropdown from 'react-bootstrap/Dropdown';
import * as actions from 'actions';
import store from 'store';
import useForm from 'utils/useForm';
import { getOrder } from 'utils/item';
import { getType } from 'utils/valet';
import Field from 'components/field';
import Button from 'components/button';
import CustomFields from 'plugins/custom-fields/fields';


const cms = wurd.block('my-items');

const ScheduledInfo = styled.div({
  backgroundColor: 'var(--brand-light)',
  padding: '.375rem .75rem',
  marginTop: '.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  fontSize: '.75rem',
  'strong': {
    fontWeight: 600,
    fontSize: '.875rem',
  }
});


export default function ItemModal({ item: itemIdOrInitialValue, close }) {
  const [current, setCurrent] = useState(0);
  const { user, settings } = store.get();
  const item = typeof itemIdOrInitialValue === 'string' ? user.items.find(item => item.id === itemIdOrInitialValue) : itemIdOrInitialValue;
  const order = getOrder(item);

  const [files, setFiles] = useState([]);

  const { fieldProps, submit, dirty } = useForm({
    initialValue: pick(item, item?.id ? ['id', 'title', 'desc', 'customFields'] : ['id', 'title', 'desc', 'type', 'state', 'customFields']),
    onSubmit: (data) => {
      return actions.items.upsert(data)
        .then((item) => {
          if (files.length > 0) {
            return Promise.all(
              files.map(file => actions.items.addItemImage(item.id, file))
            );
          }
        })
    },
    onSuccess: close,
    cms: cms.block('form'),
  });

  if (!item) return 'Not found';

  const isBulky = item.type.slice(0, 5) === 'bulky';
  const tempImages = files.map(file => ({ name: file.name, url: URL.createObjectURL(file) }));

  const sortedImages = [...item.images || [], ...tempImages].sort((a, b) => b.primary - a.primary);
  const allImages = sortedImages.length > 0 ? sortedImages : [item.opsImages?.[0] || { url: item.image || settings.items.find(i => i.type.startsWith('bulky'))?.image, name: 'Default' }];
  const currentImg = allImages[current];

  return (
    <>
      <input
        id="image-add"
        type="file"
        multiple
        name="images"
        style={{ pointerEvents: 'none', opacity: 0, position: 'absolute' }}
        onChange={e => setFiles([...e.target.files])}
      />
      <div className="card border-0">
        <Carousel
          className="card-img-top"
          interval={null}
          variant="dark"
          wrap={false}
          activeIndex={current}
          onSelect={setCurrent}
        >
          {allImages.map(img => (
            <Carousel.Item key={img.id || img.name}>
              <img
                src={img.url}
                className="d-block w-100 h-100 cover"
                style={{ minHeight: 250, filter: !img.id ? 'opacity(.4)' : null }}
                alt={img.name}
              />
              {sortedImages.length === 0 && (
                <label className="position-absolute top-50 start-50 translate-middle" htmlFor="image-add" style={{ cursor: 'pointer' }}>
                  <i className="far fa-camera fa-4x text-muted" />
                </label>
              )}
            </Carousel.Item>
          ))}
        </Carousel>
        {item.id && (
          <Dropdown className="position-absolute top-0 end-0" style={{ zIndex: 3 }}>
            <cms.Object type={Dropdown.Toggle} keys="images.delete,images.setDefault,images.add,item.delete" variant="link" size="sm" className="px-2" style={{ backgroundColor: '#fffa' }} />
            <Dropdown.Menu>
              {!currentImg?.primary && item.images.length > 0 && (
                <Dropdown.Item onClick={() => actions.items.selectItemImage(item.id, currentImg.id).then(() => setCurrent(0))} title="Set as default">
                  <i className="fs-6 far fa-map-pin" /> {cms.text('images.setDefault')}
                </Dropdown.Item>
              )}
              <Dropdown.Item as="label" title="Add more images" htmlFor="image-add" style={{ cursor: 'pointer' }}>
                <i className="far fa-plus" /> {cms.text('images.add')}
              </Dropdown.Item>
              {item.images.length > 0 && currentImg && (
                <Dropdown.Item className="btn-danger" onClick={() => actions.items.deleteItemImage(item.id, currentImg.id).then(() => setCurrent(0))} title="Delete">
                  <i className="fs-6 far fa-times" /> {cms.text('images.delete')}
                </Dropdown.Item>
              )}
              {isBulky && item.state === 'user' && !order && (
                <Dropdown.Item className="btn-danger" onClick={() => actions.items.deleteItem(item.id).then(close)} title="Delete item">
                  <i className="fs-6 far fa-trash" /> {cms.text('item.delete')}
                </Dropdown.Item>
              )}

            </Dropdown.Menu>
          </Dropdown>
        )}
        <div className="card-body d-flex flex-column gap-3">
          {order && (
            <cms.Object type={ScheduledInfo} id="schedule" keys="delivery,collection,both,generic">
              <cms.Text id="modal.scheduled.title" vars={{ type: cms.text(`schedule.${getType(order)}`) }} />
              <cms.Text id="modal.scheduled.date" vars={{ date: moment(order.date).format(settings.dateFormats.long) }} type="strong" />
            </cms.Object>
          )}
          <Field {...fieldProps('title')}/>
          <Field {...fieldProps('desc')}>
            <textarea />
          </Field>
          <CustomFields
            fieldProps={fieldProps}
            fieldSettings={settings.itemCustomFields}
            mode={item.id ? null /*not using 'update' to show read,create fields*/ : 'create'}
          />
          <Button type="button" size="lg" className="w-100 mt-4" onClick={submit} disabled={!dirty && files.length === 0}><cms.Text id="modal.save" /></Button>
        </div>
      </div>
    </>
  )
}
