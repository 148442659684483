import styled from '@emotion/styled';


const Wrapper = styled.div({
  maxWidth: 560,
  margin: '3em auto 0',
  '&:not(form)': {
    '@media (max-width: 576px)': {
      textAlign: 'center',
    }
  },
  form: {
    textAlign: 'left'
  }
});

export default ({ className = 'container', ...props }) => <Wrapper className={className} {...props} />;