import store from '../store';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import wurd from 'wurd-react';
import styled from '@emotion/styled';
import Button from 'components/button';
import Field from '../components/field';
import * as actions from '../actions';
import { getLangText } from '../utils/ui';
import useForm from '../utils/useForm';
import Paper from 'components/paper';
import Block from 'components/block';
import Title from 'components/title';
import ErrMsg from 'components/err-msg';
import ConfirmEmail from 'components/form/confirm-email';
import CustomFields from 'plugins/custom-fields/fields';
import Markdown from 'components/markdown';


const cms = wurd.block('profile');

// TODO bug local cf updates clears the file field!!

export default function Profile() {
  const user = store.get('user');
  const { userCustomFields = [] } = store.get('settings');
  const [searchParams, setSearchParams] = useSearchParams();

  const { submit, fieldProps, loading, err } = useForm({
    initialValue: user,
    onSubmit: (data, patchData) => actions.user.update(patchData),
    cms,
  });

  return (
    <Block className="container mb-2">
      <Title cms={cms.block('title')} className="my-4" vars={user} />

      {searchParams.get('email-confirmed') && (
        <div className="alert alert-success d-flex">
          {wurd.editMode ? <cms.Text id="emailConfirmed" className="flex-1" /> : <Markdown className="flex-1">{cms.text('emailConfirmed')}</Markdown>}
          <button
            type="button"
            onClick={() => setSearchParams([...searchParams].filter(([k]) => k !== 'email-confirmed'))}
            className="btn btn-default p-0"
          >
            <i className="fal fa-times fs-4" />
          </button>
        </div>
      )}

      {user && !user.emailConfirmed && <ConfirmEmail />}

      <Paper as="form" padded onSubmit={submit}>
        <Field {...fieldProps('firstName')}>
          <input required />
        </Field>
        <Field {...fieldProps('lastName')}>
          <input required />
        </Field>
        <Field {...fieldProps('email')}>
          <input required type="email" />
        </Field>
        <Field {...fieldProps('phone')} />
        <Field {...fieldProps('address')}>
          <textarea />
        </Field>
        <CustomFields
          fieldProps={fieldProps}
          fieldSettings={userCustomFields}
          mode="update"
        />

        {err && <ErrMsg err={err} className="mt-2" />}

        <div>
          <Button className="mt-4" type="submit" loading={loading}>
            <cms.Text id="submitText" />
          </Button>
        </div>
      </Paper>
    </Block>
  );
}
