import { useParams, useSearchParams } from 'react-router-dom';
import wurd from 'wurd-react';
import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';

import config from 'config';
import * as actions from 'actions';

import Loader from 'components/loader';
import NotFoundPage from 'components/not-found-page';
import SiteDetailMain from 'components/site-detail';


const cms = wurd.block('siteDetail');

export default function SiteDetailPage() {
  const { site: siteCode } = useParams();
  const [searchParams] = useSearchParams();

  const limit = Number(searchParams.get('limit')) || 25;
  const groupCode = searchParams.get('group');

  const queryClient = useQueryClient();

  // Load site
  const { data: site, ...siteQuery } = useQuery({
    queryKey: ['site', siteCode],
    queryFn: () => actions.sites.get(siteCode),
    staleTime: config.queries.site.staleTime,
    initialData: () => {
      // TODO: Simplify this by storing sites in a simple array the store
      // Similar for unitTypes
      const queryData = queryClient.getQueryData(['sites']);
      if (!queryData) return;

      // sites query is an infinite query, so we need to flatten the pages
      const sites = queryData.pages.flat();
      const site = sites?.find(s => s.code === siteCode);

      return site;
    },
  });

  // Load unit types
  const unitTypesInfiniteQuery = useInfiniteQuery({
    queryKey: ['unitTypes', { siteCode, groupCode }],
    queryFn: function ({ pageParam: offset = 0 }) {
      const groupId = groupCode && site.unitTypeGroups.find(utg => utg.code === groupCode)?.id;

      return actions.unitTypes.fetch(siteCode, {
        offset,
        limit,
        groupId: groupId || '',
      });
    },
    getNextPageParam: function (lastPage, allPages) {
      return lastPage.length < limit ? undefined : allPages.reduce((a, p) => a + p.length, 0);
    },
    enabled: !!site,
  });

  const unitTypes = unitTypesInfiniteQuery.data?.pages.flat(1);

  if (siteQuery.isLoading) return <Loader />;
  if (!site) return <NotFoundPage cms={cms.block('notFound')} />;

  return (
    <SiteDetailMain
      cms={cms}
      site={site}
      unitTypes={unitTypes}
      unitTypesInfiniteQuery={unitTypesInfiniteQuery}
      groupCode={groupCode}
    />
  );
}
