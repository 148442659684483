import { lazy, Suspense } from 'react';


export default function dynamicLoader(importCb) {
  const Component = lazy(importCb);

  return function (props) {
    return (
      <Suspense fallback={<i className="loading fa fa-spinner fa-spin" />}>
        <Component {...props} />
      </Suspense>
    );
  }
}