import api, { apiUrl } from '../../utils/api';

const apiPath = '/v1/billing/stripe';

export function getBillingUrl(opts) {
  return `${apiUrl}${apiPath}/session?` + new URLSearchParams({ returnUrl: location.href, redirect: '', types: 'card,sepa_debit', ...opts });
}

export function getIdentityUrl(opts) {
  return `${apiUrl}${apiPath}/identity-session?` + new URLSearchParams({ returnUrl: location.href, ...opts });
}

// export function getBillingToken() {
//   return api.post(`${apiPath}/session?` + new URLSearchParams({ returnUrl: location.href }));
// }

export function fetchSettings() {
  return api.get(`${apiPath}/settings`);
}

/**
 * Fetches the user's current cards/sources
 * @resolves {{ cards: array<{last4 :string}>, sepas: array<{last4 :string}> }}
 */
export function fetchCards() {
  return api.get(`${apiPath}/sources?v=2&types=sepa_debit,card`)
    .then(data => {
      if (Array.isArray(data)) return { cards: data };

      if (data?.sepas) {
        data.sepa_debits = data.sepa_debits || data.sepas; // can be removed next release
        delete data.sepas;
      }

      return data;
    });
}

/**
 * Sets the active user's new card given the Stripe token
 *
 * @param {String} stripeToken
 */
export function setCard(stripeToken) {
  return api.post(`${apiPath}/sources`, { token: stripeToken });
}
