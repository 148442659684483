import { useState } from 'react';


export default function useCache(initialData) {
  const [cache, setCache] = useState({});

  return {
    data: { ...initialData, ...cache },
    set: d => setCache(c => ({ ...c, ...d })),
    del: () => setCache({}),
  };
}
