import wurd from 'wurd-react';


const cms = wurd.block('common.footer');

export default () => {
  return (
    <cms.Object type="footer" id="poweredby" keys="url,text">
      <div className="container text-center mt-2">
        <a href={cms.text('poweredby.url')} target="_blank" className="text-muted fs-7">{cms.text('poweredby.text')}</a>
      </div>
    </cms.Object>
  );
};