import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Global, CacheProvider } from '@emotion/react';
import { marked } from 'marked';
import wurd from 'wurd-react';
import { getLuminance, darken, lighten, emphasize } from '@mui/system';
import Router from './router';
import store from './store';
import * as actions from './actions';
import languageInit from './utils/language';
import analytics from './utils/analytics';
import { cssCache } from './utils/emotion';
import { NotifyProvider } from 'utils/useNotify';
import 'bootstrap/js/dist/collapse'; // used for mobile collapse navbar
import 'bootstrap/js/dist/modal'; // used for header sign in modal
import './global.scss';


window.app_version = process.env.REACT_APP_VERSION; // useful for debugging, could be shown somewhere in UI

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  }
});

function refresh() {
  const { settings } = store.get();
  const brandDark = darken(settings.brand.linkColor, .67);

  render(
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={cssCache}>
        <NotifyProvider>
          <Global
            styles={{
              ':root': {
                '--brand': settings.brand.linkColor,
                '--brand-dark': brandDark,
                '--brand-dark-rgb': brandDark.slice(4, -1),
                '--brand-light': lighten(settings.brand.linkColor, .88),
                '--brand-text': getLuminance(settings.brand.linkColor) > .5 ? '#000' : '#fff',
                '--brand-hover': emphasize(settings.brand.linkColor, .21),
                '--brand-dark-hover': emphasize(brandDark, .21),
              }
            }}
          />
          <Router />
        </NotifyProvider>
      </CacheProvider>
    </QueryClientProvider>,
    document.getElementById('app')
  );
}

const wurdSections = [
  'billing',
  'common',
  'forgotPassword',
  'invoice',
  'invoices',
  'login',
  'signup',
  'my-items',
  'my-units',
  'profile',
  'resetPassword',
  'unitOrder',
  'selfStorage',
  'siteDetail',
  'siteMap',
  'siteList',
  'unitDetail',
  'valetOrder',
];

/**
 * TODO: Optimise initial loading
 * - Check the waterfall in browser dev tools
 * - We wait for the settings to load before anything else
 * - We then wait for the Wurd content to load before rendering the page and loading sites etc.
 * - Wurd content can definitely load in parallel with the settings and other data; it should refresh content when ready
 * - Can settings be loaded in parallel with page content?  Maybe we can use default settings until the real ones are ready,
 *     or at least start loading content for the page, e.g. /sites
 * - We can also optimise by adding some `includes` to the `settings` call, e.g `sites` (already supported) and `user` (not yet supported)
 *     This way all main calls can be made in parallel and we can start rendering the page as soon as possible
 */

actions.settings.fetch()
  .then(settings => {
    document.title = settings.companyName || settings.brand?.name;

    const link = document.querySelector('link[rel="shortcut icon"]');
    link.href = settings.brand.icon || `data:image/svg+xml,%3Csvg viewBox='0 0 215 230' fill='none' xmlns='http://www.w3.org/2000/svg' style='color:%23${settings.brand.linkColor?.slice(1)}'%3E%3Cpath d='M93.7037 219.684V122.8L10.4282 74.7207V171.845L93.7037 219.684Z' fill='currentColor'/%3E%3Cpath d='M22.2133 57.3889L104.155 10.3162L187.24 58.0459L104.222 104.737L22.2133 57.3889Z' fill='currentColor'/%3E%3Cpath d='M197.752 64.0848L197.881 64.3147V64.1591L197.752 64.0848Z' fill='currentColor'/%3E%3Cpath d='M197.881 76.0423C197.881 113.456 197.881 134.432 197.881 171.845L114.606 219.684V122.878L197.881 76.0423Z' fill='currentColor'/%3E%3Cpath d='M22.2133 57.3889L104.155 10.3162L187.24 58.0459L104.222 104.737L22.2133 57.3889Z' fill='currentColor'/%3E%3C/svg%3E%0A`; // url-encoded svg components/logo.js

    analytics.init(settings);

    languageInit(settings); // Determine the user's preferred main language

    const lang = store.get('lang');
    const wurdLang = settings.wurdLanguages?.[lang] || lang; // Get the custom language for this instance if any

    // Connect to CMS
    wurd.connect('sg-userapp', {
      editMode: 'querystring',
      lang: wurdLang,
      markdown: marked,
      onLoad: refresh,
      storageKey: 'userappContent',
    });

    wurd.load(wurdSections.join(','));

    // Re-render when store data changes
    store.subscribe(refresh);
  })
  .catch(err => {
    if (err.status === 400) { // Market not found error
      const url = new URL(window.location);
      url.searchParams.delete('market');
      window.location = url;
    }
    window.alert(err.message);
  });
