import store from '../store';


export default function Logo() {
  const { settings } = store.get();

  if (settings.brand.logo) return (
    <img
      id="logo"
      src={settings.brand.logo}
      alt={settings.companyName}
      style={{ objectFit: 'contain', height: 32 }}
    />
  );

  // default logo, useful for new setups
  return (
    <span
      className="text-primary ls-1 ms-1"
      style={{
        fontWeight: 700,
        filter: 'brightness(.8)',
        fontSize: 21,
        verticalAlign: 'middle'
      }}
    >
      {settings.companyName}
    </span>
  );
}
