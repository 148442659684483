import { useState } from 'react';
import useResize from './useResize';


const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export const isMobile = (breakpoint = 'lg') => !window.matchMedia(`(min-width: ${breakpoints[breakpoint]}px)`).matches;

export default function useMobile(breakpoint){
  const [mobile, setMobile] = useState(isMobile(breakpoint));
  useResize(() => setMobile(isMobile(breakpoint)));
  return mobile;
}
