import { useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import styled from '@emotion/styled';
import './theme';
import moment from 'moment';


const Container = styled.div({
  '& > div': {
    margin: 'auto',
  },
});

// forwardRef needed because we use this component as child of Collapse/Transition
export default function ({
  value,
  onChange,
  min,
  max,
  excludedDates,
  name,
  className,
  'aria-expanded': ariaExpanded /*from Transition parent*/,
  ...props
}) {
  const [highlighted, setHighlighted] = useState(moment());

  function isOutsideRange(d) {
    if (min && d.isBefore(min)) return true;
    if (max && d.isAfter(max)) return true;

    const date = d.format('YYYY-MM-DD');
    return excludedDates?.includes(date);
  }

  return (
    <Container className={className}>
      <DayPickerSingleDateController
        // keepOpenOnDateSelect
        date={typeof value === 'string' ? moment(value) : value}
        onDateChange={date => onChange({ target: { name, value: date ? date.format?.('YYYY-MM-DD') : null } })}
        isDayHighlighted={d => d.isSame(highlighted, 'day')}
        isOutsideRange={isOutsideRange}
        numberOfMonths={1}
        enableOutsideDays
        hideKeyboardShortcutsPanel
        focused
        {...props}
      />
    </Container>
  );
}