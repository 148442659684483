import wurd from 'wurd-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { formatOrder } from 'utils/valet';
import * as actions from 'actions';
import Title from 'components/title';
import Paper from 'components/paper';
import Block from 'components/block';
import ErrMsg from 'components/err-msg';
import Button from 'components/step-button';
import { Title as OrderTitle } from 'components/valet-upcoming-orders';


const cms = wurd.block('valetOrder.confirm');

export default function ({ nextStep, formValue }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [_account, type, items] = location.pathname.slice(1).split('/'); // /account/{collect|deliver}/{itemIds}

  const data = {
    ...formatOrder(formValue),
    ...items && { [`${type}Items`]: items.split(',') },
  };

  const orderMutation = useMutation(
    async () => {
      try {
        const order = await actions.valetOrders.create(data);
        await actions.user.fetch();
        navigate(`/account/items?order=${order.sid.toUpperCase()}`);
      } finally {
        sessionStorage.removeItem('valet-order');
      }
    }
  );

  function submit() {
    orderMutation.mutate();
  }

  return (
    <Block>
      <Title cms={cms.block('title')} className="mt-5" />

      <cms.Markdown id="info" type="div" className="mt-5" />

      <Paper padded style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <OrderTitle {...data} />
      </Paper>

      {orderMutation.error && <ErrMsg err={orderMutation.error} className="my-2" />}

      <Button type="button" onClick={submit} loading={orderMutation.isLoading}>
        <cms.Text id="submitText" />
      </Button>

    </Block>
  );
}
