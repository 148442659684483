import styled from '@emotion/styled';
import { getLangText } from 'utils/ui';

const size = 40;

const Button = styled.button({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: size,
  height: size,
  top: -size / 2,
  left: -size / 2,
  cursor: 'pointer',
  boxShadow: 'none',
  ':hover': {
    zIndex: 2
  },

  i: {
    position: 'absolute',
    marginTop: -19,
    marginLeft: -2,
    fontSize: '4rem',
    color: 'var(--brand)',
    filter: 'opacity(.75)',
  },

  span: {
    position: 'absolute',
    bottom: -24,
    whiteSpace: 'nowrap',
    fontSize: '.875rem',
    textAlign: 'center',
    color: 'var(--brand)',
    fontWeight: 600,
    fontFamily: "'InterVariable', sans-serif",
    backgroundColor: '#fffa', // so it's more visible from background
    borderRadius: '1rem',
    padding: '.25rem .75rem',
  },
});

export default ({ site, onClick }) => (
  <Button className="btn btn-default" onClick={onClick}>
    <span>{getLangText(site.title)}</span>
    <i className="fas fa-map-marker" />
  </Button>
);
