import wurd from 'wurd-react';
import DatePicker from 'components/datepicker/input';


const cms = wurd.block('common.plugins.customFields');

const isImage = name => /\.(gif|jpe?g|tiff|png|webp|bmp|svg)$/i.test(name);

function FilePicker({ name, value, onChange, required, id, readOnly }) {
  function handleChange(evt) {
    const file = evt.target.files[0];

    const blobUrl = URL.createObjectURL(file);
    return onChange({
      target: {
        name,
        value: { url: blobUrl, name: file.name, file }
      }
    });
  }

  if (!value?.url) {
    return <input
      type="file"
      id={id}
      key={value?.url}
      name={name}
      required={value?.url ? null : required}
      accept={null} // accept any
      onChange={handleChange}
      className="form-control"
      disabled={readOnly}
    />;
  }

  function handleDelete() {
    if (!value.url.startsWith('blob:') && !window.confirm(cms.text('confirmDeleteFile'))) return;

    return onChange({
      target: {
        name,
        value: ''
      }
    });
  }

  return (
    <cms.Object component="span" className={`form-control text-start ${readOnly ? 'disabled' : ''}`}>
      <a
        href={value.url}
        target="_blank"
        rel="noreferrer noopener"
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {isImage(value.name) && (
          <img
            src={value.url}
            alt={value.name}
            loading="lazy"
            style={{
              display: 'inline-block', verticalAlign: 'middle', objectFit: 'cover', width: 30, height: 30, marginRight: 8
            }}
          />
        )}
        {value.name}
      </a>
      {!readOnly && (
        <button
          type="button"
          keys="confirmDeleteFile,docSizeTooLarge"
          onClick={handleDelete}
          className="btn btn-link btn-sm"
        >
          <i className="far fa-times" />
        </button>
      )}
    </cms.Object>
  );
}


/**
 *
 * @param {*} props
 * @param {string} props.code
 * @param {'string'|'number'|'integer'|'date'} props.type
 * @param {object} props.title
 * @param {array} props.enum
 */
export default function CustomInput({ type, enum: _enum, ...props }) {
  switch (type) {
    case 'file': {
      return <FilePicker {...props} />;
    }
    case 'date': {
      return props.readOnly
        ? <input type="text" {...props} />
        : <DatePicker isOutsideRange={() => false} {...props} />;
    }
    case 'date-time': {
      return <input type="datetime-local" {...props} />
    }
    case 'integer': {
      return <input type="number" step="1" {...props} />;
    }
    case 'boolean': {
      return <input type="checkbox" {...props} disabled={props.readOnly} />;
    }
    default: {
      return _enum
        ? (
          <select {...props} disabled={props.readOnly} className="form-select">
            {!props.multiple && <option value=""></option>}
            {_enum.map(v => <option key={v} value={v}>{v}</option>)}
          </select>
        ) : <input type={type === 'string' ? 'text' : type} {...props} />;
    }
  }
}
