import wurd from 'wurd-react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import * as actions from '../actions';
import useForm from 'utils/useForm';
import Paper from 'components/paper';
import Title from 'components/title';
import Field from 'components/field';
import Button from 'components/button';
import ErrMsg from 'components/err-msg';
import Captcha from 'plugins/captcha';

const cms = wurd.block('forgotPassword');

const SuccessInfo = styled.div({
  p: { margin: 0 },
});

const Container = styled('div')({
  maxWidth: 460,
  padding: 15,
  margin: 'auto',
});

export default function ForgotPassword() {
  const { submit, fieldProps, isLoading, err, isSuccess } = useForm({
    cms,
    onSubmit: actions.user.sendForgotPasswordEmail,
  });

  return (
    <Container>
      <Title cms={cms.block('title')} className="my-5" />

      <Paper padded>
        {isSuccess
          ? (
            <div className="d-flex flex-column gap-2">
              <div className="alert alert-success mt-4 py-1"><cms.Text id="sent.title" type="strong" /></div>
              <cms.Markdown type={SuccessInfo} id="sent.desc" className="text-muted fs-6" />
            </div>
          ) : (
            <form onSubmit={submit}>
              <Captcha />
              <div className="d-flex flex-column gap-2">
                <Field
                  {...fieldProps('email')}
                  type="email"
                  required
                  autoComplete="username"
                  autoFocus
                />
              </div>

              {err && <ErrMsg err={err} className="mt-4 py-1" />}

              <Button type="submit" className="w-100 text-center mt-5 mb-2" loading={isLoading}><cms.Text id="submitText" /></Button>
            </form>
          )}
      </Paper>
    </Container>
  )
}
