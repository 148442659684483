import createCache from '@emotion/cache';
import createEmotion from '@emotion/css/create-instance';

export const cssCache = createCache({
  key: 'app',
  nonce: document.getElementById('app').dataset.styleNonce,
});

const { css } = createEmotion(cssCache);
export { css as vanillaCss };
