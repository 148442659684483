/**
 * @param {Object} settings
 * @param {Object} user
 *
 * @return {Boolean}
 */
export const hasBillingMethod = function (settings, user, mode) {
  // If auto billing not supported by the customer then OK, all done
  if (!settings.billing) {
    return true;
  }

  // If this particular user has 'invoice' then they don't require the billing setup
  if (user?.billing?.type === 'invoice') {
    return true;
  }

  // Otherwise billing method must match the defined billing method
  if (mode !== 'setup') return user?.billing?.type === settings.billing;

  return false; // we'll always load billing plugins in mode="setup" to check for existing payment methods
};
