import wurd from 'wurd-react';
import { useState } from 'react';
import store from 'store';
import ErrMsg from 'components/err-msg';
import Counter from 'components/counter';
import Button from 'components/step-button';
import Title from 'components/title';
import analytics from 'utils/analytics';
import { getLangText } from 'utils/ui';


const cms = wurd.block('valetOrder.boxes');

export default function ({ nextStep, data, validate, inputProps, formValue }) {
  const settings = store.get('settings');
  const [validationError, setValidationError] = useState();

  async function submit(e) {
    e.preventDefault();
    if (typeof validate === 'function') {
      try {
        validate(formValue.boxes);
      } catch (e) {
        setValidationError(e);
      }
    }

    if (Object.keys(formValue.boxes || {}).length > 0) {
      const { market } = Object.fromEntries(new URLSearchParams(window.location.search));
      analytics.selectItem({
        item_list_name: 'Boxes',
        items: settings.items.map((o, index) => {
          const quantity = formValue.boxes[o.type];
          if (!quantity) return null;
          return {
            item_id: o.id,
            item_name: getLangText(o.title, ''),
            item_brand: market,
            price: o.price,
            quantity,
            index,
          }
        }).filter(Boolean),
      });
    }

    nextStep({ boxes: formValue.boxes || {} });
  }

  return (
    <>
      <Title cms={cms.block('title')} className="mt-5" />

      <cms.Markdown id="info" className="text-muted mt-3" />

      <form onSubmit={submit} className="mt-5">
        <ul className="list-unstyled d-flex flex-column gap-3 m-auto" style={{ maxWidth: 560 }}>
          {settings.items.sort((a, b) => ((a.order || 0) - (b.order || 0)) || (a.price - b.price) || a.type.localeCompare(b.type)).map(item => {
            if (item.quantity === 0 && item.maxQuantity === 0) return null;

            return (
              <li key={item.type}>
                <Counter
                  {...inputProps(`boxes.${item.type}`, 0)}
                  item={{ ...item, code: item.type }}
                />
              </li>
            );
          })}
        </ul>

        {validationError && <ErrMsg err={validationError} className="mt-2 mx-auto" style={{ maxWidth: 560 }} />}

        <Button><cms.Text id="submitText" /></Button>
      </form>
    </>
  )
}
