import { useCallback } from 'react';
import wurd, { WurdText } from 'wurd-react';
import styled from '@emotion/styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Collapse from 'bootstrap/js/dist/collapse';
import store from '../store';
import * as actions from '../actions';
import useMobile from 'utils/useMobile';
import Logo from './logo';


const cms = wurd.block('common.header');


const Nav = styled.nav({
  borderTop: '.25rem solid var(--brand)',
  backgroundColor: '#fffc',
  backdropFilter: 'blur(20px)',
  paddingTop: '.25rem',
  paddingBottom: '.25rem',
  borderBottom: 'var(--border-light)',
  whiteSpace: 'nowrap',
  '.nav-link > .fal': {
    fontSize: '1.25rem',
  },
  '.navbar-toggler > .fal': {
    fontSize: '150%',
    fontWeight: 100,
    width: '1rem',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  '.navbar-toggler:not(.collapsed) > .fal:before': {
    content: '"\\f00d"', // fa-times when navbar menu is visible
  }
});

// const LangMenu = styled.div({
//   marginRight: -30,
//   zIndex: 999, // below user menu
// });

const Dialog = styled.div({
  maxWidth: 409,
  margin: '5rem auto',
});

const DropdownHover = styled.li({
  '&:focus-within > .dropdown-menu, &:hover > .dropdown-menu': {
    display: 'block',
    opacity: 1,
  },
  '&:hover > .dropdown-menu': {
    zIndex: 1001, // have it above an other opened focused menu
  },
});

function collapseMobileHandler() {
  // check if we're in mobile mode
  if (window.getComputedStyle(document.querySelector('header .navbar-toggler')).display === 'none') return;

  new Collapse(document.getElementById('navbarCollapse')).toggle();
}

function Dropdown({ className = '', children, ...rest }) {
  return (
    <DropdownHover
      className={`nav-item dropdown ${className}`}
      {...rest}
      onMouseDown={e => {
        e.currentTarget.firstElementChild.ariaExpanded = !e.currentTarget.contains(document.activeElement);
      }}
      onClick={e => {
        if (e.currentTarget.firstElementChild.ariaExpanded === 'false' && e.currentTarget.contains(document.activeElement)) {
          document.activeElement.blur();
        }
      }}
    >
      {children}
    </DropdownHover>
  );
}


export default function Header() {
  const { user, settings, lang: currentLang } = store.get();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile('md');
  const logout = useCallback(() => {
    actions.user.logout()
      .finally(() => {
        navigate('/account');
      })
  }, [history]);

  const selfStorage = settings.sites?.length;
  const valetStorage = settings.items?.length;

  const mainMenu = selfStorage && !valetStorage
    ? (
      <>
        <li className="nav-item ms-md-3">
          <Link
            className={`nav-link px-4 ${/\/units/.test(location.pathname) ? 'active' : ''}`}
            to="/account/units"
          >
            <cms.Text id="link.myUnits" />
          </Link>
        </li>
        <li className="nav-item ms-md-1">
          <Link
            className={`nav-link btn-primary px-4 ${/\/sites/.test(location.pathname) ? 'active' : ''}`}
            to="/sites"
          >
            <cms.Text id="link.bookUnit" />
          </Link>
        </li>
      </>
    ) : valetStorage && !selfStorage
      ? (
        <>
          <li className="nav-item ms-md-3">
            <Link
              className={`nav-link px-4 ${/\/items/.test(location.pathname) ? 'active' : ''}`}
              to={`/account/items${location.search}`}
            >
              <cms.Text id="link.myItems" />
            </Link>
          </li>
          <li className="nav-item ms-md-1">
            <Link
              className={`nav-link btn-primary px-4 ${/\/valet/.test(location.pathname) ? 'active' : ''}`}
              to={`/valet${location.search}`}
            >
              <cms.Text id="link.createOrder" />
            </Link>
          </li>
        </>
      ) : !!(selfStorage && valetStorage) && (
        <>
          <Dropdown key="self-storage-menu" className="ms-md-3">
            <Link className={`nav-link px-4 ${/\/(units|sites)/.test(location.pathname) ? 'active' : ''}`} id="selfStorageMenu" to="/account/units">
              <cms.Text id="link.selfStorage" />
            </Link>
            <cms.Object
              type="ul"
              id="link"
              keys="myUnits,bookUnit"
              className={`dropdown-menu start-50 ${!isMobile ? 'translate-middle-x' : ''}`}
              data-popper-placement="top-end"
              aria-labelledby="selfStorageMenu"
              data-bs-auto-close="true"
            >
              <li><Link className={`dropdown-item nav-link ${/\/units/.test(location.pathname) ? 'active' : ''}`} to="/account/units"><cms.Text id="link.myUnits" /></Link></li>
              <li><Link className={`dropdown-item nav-link ${/\/sites/.test(location.pathname) ? 'active' : ''}`} to="/sites"><cms.Text id="link.bookUnit" /></Link></li>
            </cms.Object>
          </Dropdown>
          <Dropdown key="valet-storage-menu" className="ms-md-3">
            <Link className={`nav-link px-4 ${/\/(items|valet)/.test(location.pathname) ? 'active' : ''}`} id="valetStorageMenu" to="/account/items">
              <cms.Text id="link.valetStorage" />
            </Link>
            <cms.Object
              type="ul"
              id="link"
              keys="myItems,createOrder"
              className={`dropdown-menu start-50 ${!isMobile ? 'translate-middle-x' : ''}`}
              data-popper-placement="top-end"
              aria-labelledby="valetStorageMenu"
              data-bs-auto-close="true"
            >
              <li><Link className={`dropdown-item nav-link ${/\/items/.test(location.pathname) ? 'active' : ''}`} to={`/account/items${location.search}`}><cms.Text id="link.myItems" /></Link></li>
              <li><Link className={`dropdown-item nav-link ${/\/valet/.test(location.pathname) ? 'active' : ''}`} to={`/valet${location.search}`}><cms.Text id="link.createOrder" /></Link></li>
            </cms.Object>
          </Dropdown>
        </>
      );

  const userMenu = user
    ? (
      <Dropdown key="user-menu" className="ms-md-3">
        <Link className={`nav-link px-4 ${/\/(profile|invoices|billing)/.test(location.pathname) ? 'active' : ''}`} id="userMenu" to="/account/profile">
          <i className="fal fa-user-circle fs-3 me-2" />
          {user.firstName} {user.lastName}
        </Link>
        <cms.Object
          type="ul"
          id="link"
          keys="profile,invoices,billing,help,terms,logout"
          className={`dropdown-menu start-50 ${!isMobile ? 'translate-middle-x' : ''}`}
          data-popper-placement="top-end"
          aria-labelledby="userMenu"
          data-bs-auto-close="true"
        >
          <li><Link className={`dropdown-item nav-link ${/\/profile/.test(location.pathname) ? 'active' : ''}`} to="/account/profile">{cms.text('link.profile')}</Link></li>
          <li><Link className={`dropdown-item nav-link ${/\/invoices/.test(location.pathname) ? 'active' : ''}`} to="/account/invoices">{cms.text('link.invoices')}</Link></li>
          <li><Link className={`dropdown-item nav-link ${/\/billing/.test(location.pathname) ? 'active' : ''}`} to="/account/billing">{cms.text('link.billing')}</Link></li>
          {settings.helpUrl && <li><a className="dropdown-item nav-link" href={settings.helpUrl} target="_blank">{cms.text('link.help')}</a></li>}
          {settings.termsUrl && <li><a className="dropdown-item nav-link" href={settings.termsUrl} target="_blank">{cms.text('link.terms')}</a></li>}
          <li><button className="dropdown-item nav-link" onClick={logout}>{cms.text('link.logout')}</button></li>
        </cms.Object>
      </Dropdown>
    ) : user === null
      ? (
        <>
          <li key="signin-btn" className="nav-item">
            <Link to={`/account/login${location.search}`} className={`dropdown-item nav-link px-md-2 ${/\/login/.test(location.pathname) ? 'active' : ''}`}><cms.Text id="link.login" /></Link>
          </li>
          <li key="signup-btn" className="nav-item">
            <Link to={`/account/signup${location.search}`} className={`dropdown-item nav-link px-md-2 ${/\/signup/.test(location.pathname) ? 'active' : ''}`}><cms.Text id="link.signup" /></Link>
          </li>
        </>
      ) : <i className="far fa-spinner fa-spin" />;

  const languagesMenu = settings.languages.length > 1 && (
    !isMobile
      ? (
        <Dropdown className={user === null ? '' : 'ms-md-3'}>
          <button className="btn nav-link px-3 d-inline-flex align-items-center" id="navbarLang" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fal fa-globe fs-5" />
          </button>
          <cms.Object type="ul" id="link" keys={settings.languages.join(',')} className="dropdown-menu" style={{ right: '-.75rem' }} data-popper-placement="top-end"
            aria-labelledby="navbarLang">
            {settings.languages.map(lang => (
              <li key={lang}><button className={`dropdown-item nav-link px-4 ${lang === currentLang ? 'active' : ''}`} onClick={() => actions.user.setLanguage(lang)}>{cms.text(`link.${lang}`) || lang}</button></li>
            ))}
          </cms.Object>
        </Dropdown>
      ) : (
        <cms.Object type="ul" id="link" keys={settings.languages.join(',')} className="dropdown-menu" data-popper-placement="top-end"  aria-labelledby="navbarLang">
          <li><button className="dropdown-item nav-link active"><i className="fa-fw fal fa-globe me-2" />{cms.text(`link.${currentLang}`) || currentLang}</button></li>
          {settings.languages.filter(lang => lang !== currentLang).map(lang => (
            <li key={lang}>
              <button className="dropdown-item nav-link" onClick={() => actions.user.setLanguage(lang)}><i className="fa-fw fal me-2" />{cms.text(`link.${lang}`) || lang}</button></li>
          ))}
        </cms.Object>
      )
  );

  return (
    <header>
      <Nav className="navbar navbar-expand-md fixed-top">
        <div className="container-xxl">
          <a href={settings.siteUrl || '/'} className="navbar-brand">
            <Logo />
          </a>
          <button className="navbar-toggler collapsed text-primary" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
            aria-label="Toggle navigation">
            <i className="fal fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0 w-100 justify-content-end gap-1" onClick={collapseMobileHandler}>
              {mainMenu}
              {userMenu}
              {languagesMenu}
            </ul>
          </div>
        </div>
      </Nav>
    </header>
  );
}
