import wurd from 'wurd-react';

import Paper from 'components/paper';
import Block from 'components/block';
import Title from 'components/title';

const cms = wurd.block('unitOrder.success');


export default function UnitOrderSuccessStep({
  order,
}) {
  return (
    <Block>
      <Title cms={cms.block('title')} className="mt-5" />

      <Paper padded className="mt-3">
        <cms.Markdown id="intro" />
      </Paper>
    </Block>
  )
}
