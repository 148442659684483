import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import store from '../store';
import { apiUrl } from '../utils/api';


const Container = styled.div({
  '> .g_id_signin svg': {
    display: 'none' // hack for CSP load time
  }
});

export default ({ signup, redirectUrl = location.href }) => {
  const ref = useRef();

  const { googleClientId, nonce, user } = store.get();

  const returnUrl = new URL(redirectUrl);
  returnUrl.searchParams.set('_r', 'g');
  if (signup) returnUrl.searchParams.set('signup', '');

  const proxyUrl = `https://storeganise.com/signin?r=${returnUrl}`;

  // for some reaon it only works like this, not in jsx
  useEffect(() => {
    if (googleClientId && ref.current) {
      const s = document.createElement('script');
      s.src = 'https://accounts.google.com/gsi/client';
      s.nonce = nonce;
      s.async = true;

      const d1 = document.createElement('div');
      d1.id = 'g_id_onload';
      const { market = '' } = Object.fromEntries(new URLSearchParams(window.location.search));
      Object.assign(d1.dataset, {
        client_id: googleClientId,
        login_uri: `${apiUrl}/v1/auth/google?redirectUrl=${proxyUrl}&errorUrl=${location.href}${signup ? `&signup&marketId=${market}` : ''}`,
        auto_prompt: 'false',
        // ux_mode: 'redirect',
      });
      const d2 = document.createElement('div');
      d2.className = 'g_id_signin mt-3';
      Object.assign(d2.dataset, {
        type: 'standard',
        // size: 'large',
        theme: 'outline',
        text: 'sign_in_with',
        shape: 'rectangular',
        width: Math.min(400, screen.width * .9), // max is 400 https://developers.google.com/identity/gsi/web/reference/html-reference
        logo_alignment: 'center',
      });

      ref.current.append(s, d1, d2);
    }
  }, [googleClientId]);

  if (!googleClientId) return <div />;

  return <Container ref={ref} />;
}

