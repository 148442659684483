import wurd from 'wurd-react';

import useForm from 'utils/useForm';
import analytics from 'utils/analytics';
import { getLangText, sortByCode } from 'utils/ui';

import Button from 'components/step-button';
import Counter from 'components/counter';
import Title from 'components/title';


const cms = wurd.block('unitOrder.products');

export default function UnitOrder_Products({
  site,
  order,
  nextStep,
}) {
  const { inputProps, formValue, submit, isLoading } = useForm({
    initialValue: order,
    cms: cms.block('products'),
    onSubmit: async function (data) {
      if (Object.keys(formValue.products || {}).length > 0) {
        analytics.selectItem({
          item_list_name: 'Products',
          items: site.products.map((p, index) => {
            const quantity = formValue.products[p.id];
            if (!quantity) return null;
            return {
              item_id: p.id,
              item_name: getLangText(p.title, ''),
              item_brand: getLangText(site.title, ''),
              price: p.price,
              quantity,
              index,
            }
          }).filter(Boolean),
        });
      }

      await nextStep({
        products: formValue.products || {},
      });
    }
  });

  return (
    <>
      <Title cms={cms.block('title')} className="mt-5" />
      <form onSubmit={submit}>
        <ul className="list-unstyled m-auto d-flex flex-column gap-3" style={{ maxWidth: 560 }}>
          {site.products.sort(sortByCode).map(product => (
            <li key={product.id}>
              <Counter
                {...inputProps(`products.${product.id}`, 0)}
                item={{
                  ...product,
                  code: product.id,
                  info: product.description,
                  maxQuantity: Math.min(product.maxQuantity, product.type === 'movein' && product.trackInventory ? product.inventory : Infinity)
                }}
                soldOutText={<cms.Text id="soldOut" />}
              />
            </li>
          ))}
        </ul>

        <Button loading={isLoading}><cms.Text id="submitText" /></Button>
      </form>
    </>
  );
}
