import store from '../store';

import { getLangText } from './ui';

export function getItemTitle(item) {
  return item.title || item.opsTitle || getLangText(item.typeTitle);
}

export function getOrder(item) {
  if (!item) return null;

  const { valetOrders } = store.get('user');

  //If explicitly set on the item use that
  if (item.valetOrderId) {
    return valetOrders.find(order => order.id === item.valetOrderId);
  }

  //Otherwise the item may have been ordered but not processed yet so check the order
  const itemId = item.id;
  const listName = (item.state === 'user') ? 'collectItems' : 'deliverItems';

  return valetOrders.find(order => order[listName].includes(itemId));
}

export function getImage(item) {
  return item.images?.find(img => img.primary)?.url
    || item.images?.[0]?.url
    || item.opsImages?.[0]?.url
    || item.image;
}


/**
 * Formats items as CSV
 *
 * @param {Array<Object>} items
 * @return {String} csv
 */
export function toCsv(items) {
  const { itemCustomFields = [] } = store.get('settings');

  return items.map(item => {
    return {
      sid: item.sid.toUpperCase(),
      // type: item.type,
      type: getLangText(item.typeTitle),
      desc: item.desc,
      title: getItemTitle(item),
      image: item.image,
      images: item.images.sort((a, b) => b.primary - a.primary).map(img => img.url),
      opsImages: item.opsImage?.map(img => img.url),
      valetOrderSid: getOrder(item)?.sid.toUpperCase(),
      ...Object.fromEntries(
        itemCustomFields?.map(({ code }) => {
          const value = item.customFields?.[code];
          return [`customFields.${code}`, value?.url || value];
        })
      ),
      created: item.created,
      updated: item.updated,
    };
  });
}
