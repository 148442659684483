import store from 'store';


export default function ({ cms, className = '', error, ...rest }) {
  const { settings } = store.get();

  return (
    <div className={`form-check text-muted ${className}`} {...rest}>
      {settings.requireAgreeTerms
        ? (
          <>
            <input type="checkbox" required id="terms-check" className="form-check-input" />
            <cms.Markdown id="agreeTerms" type="label" className="form-check-label" htmlFor="terms-check" vars={{ termsUrl: settings.termsUrl }} />
            {error && <div className="invalid-feedback">{error}</div>}
          </>
        ) : (
          <cms.Markdown id="agreeTerms" vars={{ termsUrl: settings.termsUrl }} />
        )}
    </div>
  );
}