import wurd from 'wurd-react';
import { getLangText } from 'utils/ui';
import Button from 'components/button';
import Title from 'components/title';
import ItemList from 'components/item/list';
import store from 'store';


const cms = wurd.block('valetOrder.items');

export default function ({ nextStep, type, data, inputProps, formValue }) {
  const { settings, user } = store.get();

  const collectOrDeliverItems = type === 'collect' ? 'collectItems' : 'deliverItems';
  const { name, value: items, onChange } = inputProps(collectOrDeliverItems);

  const availItems = user.items
    .filter(item => type === 'collect' ? item.state === 'user' : item.state !== 'user')
    .sort((a, b) => items.includes(b.id) - items.includes(a.id));

  function submit(e) {
    e.preventDefault();
    nextStep({
      [collectOrDeliverItems]: formValue[collectOrDeliverItems] || [],
    });
  }

  function toggleSelected(item, checked) {
    const isIncluded = items.includes(item.sid);
    onChange(
      checked
        ? [...items, ...isIncluded ? [] : [item.sid]]
        : items.filter(sid => sid !== item.sid)
    );
  }

  const selectedItems = availItems.filter(item => items.includes(item.sid)).map(item => item.id);

  return (
    <>
      <Title cms={cms.block(`${type}.title`)} className="mt-5" />

      <form onSubmit={submit}>
        <ItemList
          items={availItems}
          selectedItems={selectedItems}
          toggleSelected={toggleSelected}
          buttonIcon={null}
          disabled={order => false}
        />

        <Button className="mt-4"><cms.Text id="submitText" /></Button>
      </form>
    </>
  )
}
