import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
import { vanillaCss } from 'utils/emotion';


ThemedStyleSheet.registerInterface({
  create(styleHash) {
    return styleHash;
  },
  resolve(styles) {
    return { className: vanillaCss(styles) };
  },
  flush() { }
});

ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderBottom: 0,
        // borderBottomFocused: 0,
      },
    },
    color: {
      ...DefaultTheme.reactDates.color,
      // background: 'transparent',
      border: 'transparent',
      text: 'var(--bs-dark)',

      highlighted: {
        backgroundColor: 'var(--brand-light)',
        backgroundColor_active: 'var(--brand-light)',
      },
      selected: {
        backgroundColor: 'var(--brand)',
        backgroundColor_active: 'var(--brand-hover)',
        backgroundColor_hover: 'var(--brand-hover)',
        color: 'var(--brand-text)',
        color_active: 'var(--brand-text)',
        color_hover: 'var(--brand-text)'
      },
    },
    spacing: {
      ...DefaultTheme.reactDates.spacing,
      displayTextPaddingTop_small: 6,
      displayTextPaddingBottom_small: 3,
      displayTextPaddingLeft_small: 2,
      displayTextPaddingRight_small: 2,
    },
    sizing: {
      ...DefaultTheme.reactDates.sizing,
      inputWidth: '100%',
      inputWidth_small: '100%',
    },
    font: {
      ...DefaultTheme.reactDates.font,
      input: {
        ...DefaultTheme.reactDates.font.input,
        weight: 'inherit',
        size_small: 16,
      }
    },
  }
});
