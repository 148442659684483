import { Fragment } from 'react';
import wurd from 'wurd-react';
import styled from '@emotion/styled';
import Field from 'components/field';


const Container = styled.div(`
  width: 320px;

  flex-wrap: wrap;
  
  .form-floating {
    flex: 1;
  }
  > :nth-child(n+3) {
    width: 100%;
  }
`);

export default ({ children, ...props }) => (
  <Container className="m-auto mt-1 input-group">
    <span className="input-group-text"><i className="fa-fw far fa-clock" /></span>
    <Field as={wurd.editMode ? undefined : Fragment} {...props}>{children}</Field>
  </Container>
);