import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import loadScript from 'load-script2';
import store from 'store';


function PdfCanvas({ page, scale = 1 }) {
  const canvasRef = useRef();
  useEffect(() => {
    const viewport = page.getViewport({ scale });
    canvasRef.current.height = viewport.height;
    canvasRef.current.width = viewport.width;
    const renderContext = {
      canvasContext: canvasRef.current.getContext('2d'),
      viewport,
    };
    page.render(renderContext).promise
      .catch(err => console.log('Error on page', err, page));
  }, []);

  return <canvas ref={canvasRef} />;
}

// pdf preview using pdfjs lib
export default function PdfPreview({ arrayBuffer, scale = 1 }) {
  const [pdfPages, setPdfPages] = useState([]);

  const pdfjsQuery = useQuery('pdfjs', () => loadScript('https://mozilla.github.io/pdf.js/build/pdf.mjs', { type: 'module', nonce: store.get('nonce') }));

  useEffect(() => {
    const { pdfjsLib } = globalThis;
    if (!pdfjsLib || !arrayBuffer) return;

    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://mozilla.github.io/pdf.js/build/pdf.worker.mjs';
    pdfjsLib.getDocument(arrayBuffer).promise
      .then(async pdfDoc => {
        for (let i = 1; i <= pdfDoc.numPages; i++) {
          const page = await pdfDoc.getPage(i);
          setPdfPages(ps => [...ps, page]);
        }
      })
      .catch(err => {
        console.log('ERR', err);
      });
  }, [pdfjsQuery.isSuccess, !!arrayBuffer]);

  return (
    <div className="overflow-auto" style={{ maxHeight: 'min(30vh, 360px)' }}>
      {pdfPages.map((page, i) => <PdfCanvas key={i} page={page} scale={scale} />)}
    </div>
  );
}