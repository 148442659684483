import wurd, { WurdText } from 'wurd-react';
import { hasBillingMethod } from '../utils/user';
import useSearchData from 'utils/useSearchData';
import useSessionStorage from 'utils/useSessionStorage';
import store from '../store';
import PlanStep from 'components/valet-order-steps/plan';
import BoxesStep from 'components/valet-order-steps/boxes';
import ProductsStep from 'components/valet-order-steps/products';
import AreaStep from 'components/valet-order-steps/area';
import TimeStep from 'components/valet-order-steps/time';
import BillingStep from 'components/valet-order-steps/billing';
import ConfirmStep from 'components/valet-order-steps/confirm';
import useForm from 'utils/useForm';
import { formatOrder, hasOrderedItems } from 'utils/valet';
import Progress from 'components/valet-progress';
import Summary from 'components/valet-summary';
import Loader from 'components/loader';
import Split from 'components/split-page';


const stepComponents = {
  plan: PlanStep,
  boxes: BoxesStep,
  products: ProductsStep,
  area: AreaStep,
  time: TimeStep,
  billing: BillingStep,
  confirm: ConfirmStep,
}


/*
Valet order
1. Plan (if applicable)
2. Boxes / bulky items
3. Products (if applicable)
4. Area + account / login
5. Delivery / collection date / time
6. Billing (if applicable - if no gateway setup, skip)
7. Success with final order summary
*/
function getStep(data) {
  if (data.session_id) return 'billing'; // stripe or cybersource checkout callback

  const { settings, user } = store.get();

  if (settings.plans?.length > 0 && !(user?.planId || data.plan)) return 'plan';

  if (settings.items?.length > 0 && Object.values(data.boxes || {}).filter(Boolean).length < 1) {
    return {
      toString: () => 'boxes',
      validate: data => {
        if (!Object.values(data || {}).some(v => v > 0)) {
          throw Object.assign(new Error(), { message: <WurdText id="valetOrder.boxes.mustSelect" /> });
        }
      }
    };
  }

  if (settings.products?.length > 0 && data.products === undefined) return 'products';

  if (!data.date || !data.timeslot) return 'time';

  if (hasOrderedItems({ boxCounts: data.boxes }) && data.collect === 'later') {
    if (data.date >= data.collectDate || !data.collectDate || !data.collectTimeslot) {
      return 'time'; //The collection date must be set after the delivery date;
    }
  }

  if (!user || data.area === undefined) return 'area';

  if (!hasBillingMethod(settings, user, 'setup') && data.pay !== '✓') return 'billing';

  return 'confirm';
}

const cms = wurd.block('valetOrder');

export default function () {
  const { settings, user } = store.get();
  const [data, setData] = useSearchData();
  const cache = useSessionStorage('valet-order', [data]); // used for keeping old values when going back in previous steps

  const step = getStep(data);

  const combinedData = {
    collect: 'immediately',
    boxes: Object.fromEntries(settings.items
      .filter(item => item.quantity > 0)
      .map(item => [item.type, item.quantity])
    ),
    products: Object.fromEntries(settings.products
      .filter(product => product.quantity > 0)
      .map(product => [product.slug, product.quantity])
    ),
    ...cache.data,
    plan: undefined /* skip plan caching */,
    ...data,
  };

  const formProps = useForm({
    initialValue: combinedData,
    cms: cms.block(step),
  });

  const StepComponent = stepComponents[step];

  const nextStep = (d, opts) => {
    if (!opts?.skipCache) cache.set({ ...formProps.formValue, ...d });
    setData({ ...data, ...d || formProps.formValue });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Progress data={data} step={`${step}`} />

      <Split className="position-relative align-items-lg-start">
        <Summary order={formatOrder(formProps.formValue)} className="order-lg-2" />

        <div className="flex-1 px-2 px-sm-0">
          <StepComponent
            {...combinedData}
            {...step}
            {...formProps}
            data={data}
            nextStep={nextStep}
            showPromoCode
            mode="setup"
          />
        </div>
      </Split>
    </>
  );
}
