import Title from 'components/title';


export default function NotFoundPage({
  cms,
}) {
  return (
    <Title cms={cms} className="mt-5 text-center" />
  );
}
