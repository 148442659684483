
export async function download(rows, downloadName) {
  const { default: PapaCsv } = await import('papaparse');

  const csvText = PapaCsv.unparse(rows, { escapeFormulae: true });
  const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = downloadName || true;
  a.click();
}
