import api from '../utils/api';
import errHandler from './_err-handler';


export function fetch(search) {
  return api.get(`/v1/sites${search ? `?${new URLSearchParams(search)}` : ''}`)
    .catch(errHandler);
}

// We set the includes here because using different query params negates the cached data in react-query
export function get(idOrCode, params = {
  include: ['minmaxBookingDates'],
}) {
  return api.get(`/v1/sites/${idOrCode}${params ? `?${new URLSearchParams(params)}` : ''}`)
    .catch(errHandler);
}
