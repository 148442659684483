import api from '../utils/api';
import store from '../store';
import errHandler from './_err-handler';


export function fetch() {
  const { market } = Object.fromEntries(new URLSearchParams(window.location.search));

  return api.get(`/v1/settings?include=sites,userCustomFields,unitRentalCustomFields,itemCustomFields${market ? '&market=' + market : ''}`)
    .then(settings => {
      store.set({ settings });

      return settings;
    })
    .catch(errHandler);
}

export function fetchTimeslots() {
  const { market } = Object.fromEntries(new URLSearchParams(window.location.search));

  return api.get(`/v1/settings/timeslots${market ? '?market=' + market : ''}`)
    .catch(errHandler);
}
