import { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import { useQuery } from 'react-query';
import Modal from 'react-bootstrap/Modal';

import store from 'store';
import * as actions from 'actions';
import { getLangText } from 'utils/ui';
import useSearchData from 'utils/useSearchData';

import SuccessOrderBox from 'components/success-order-box';
import Notification from 'components/notification';
import Empty from 'components/empty';
import Loader from 'components/loader';
import UnitsBySite from 'components/my-units/units-by-site';


const cms = wurd.block('my-units');

const Parens = styled.span({
  '&::before': {
    content: '"("'
  },
  '&::after': {
    content: '")"'
  }
});

const SubNav = styled.div({
  backgroundColor: '#fffc',
  zIndex: 2,
  borderTop: 'var(--border-light)',
  borderBottom: 'var(--border-light)',
  fontSize: '.875rem',
  whiteSpace: 'nowrap',
  overflowX: 'auto',
  '.title': {
    display: 'inline-block',
    padding: '1rem 5vw',
    borderRight: 'var(--border-light)',
    fontWeight: 600,
  },
  'strong': {
    fontWeight: 600,
  },
  'a:nth-of-type(2)': {
    marginLeft: '2vw',
  },
  a: {
    padding: '1rem 3vw'
  },
  '@media (min-width: 992px)': {
    marginBottom: '1.25rem',
  },
});

export default function MyUnits() {
  const { user } = store.get();
  const [modal, setModal] = useState();
  const navRef = useRef();
  const [curSite, setCurSite] = useState();
  const [sd, setSearchData] = useSearchData();
  const location = useLocation();

  const siteIds = [...new Set(user?.units?.map(u => u.siteId) || [])];
  const { data: sites, ...sitesQuery } = useQuery(['sites', siteIds.join()], () => actions.sites.fetch({ ids: siteIds }), { enabled: !!user });

  function stickyNavScroll() {
    let cur;
    navRef.current.querySelectorAll('a').forEach(a => {
      const section = document.getElementById(a.hash.slice(1));
      if (!cur && section) {
        const rect = section.getBoundingClientRect();
        if (rect.bottom >= 300) {
          cur = a.hash.slice(7);
        }
      }
    });
    setCurSite(cur);
  }

  useEffect(() => {
    if (!user || !navRef.current) return;

    stickyNavScroll();
    document.addEventListener('scroll', stickyNavScroll, { passive: true });
    return () => document.removeEventListener('scroll', stickyNavScroll);
  }, [user]);

  useEffect(() => {
    const el = document.getElementById(location.hash.slice(1));
    if (el?.scrollIntoViewIfNeeded) {
      el.scrollIntoViewIfNeeded();
    }
  }, [user, location.hash]);

  if (user === undefined || sitesQuery.isLoading) return <Loader />; // wait for load

  const unitsBySite = [
    ...user?.units?.reduce((m, unit) => {
      const site = sites.find(site => site.id == unit.siteId);
      if (!site) return m;
      if (unit.rental.activeJobs.some(job => job.type === 'unit_moveIn' && job.step === 'await_submitOrder')) return m;
      return m.set(site, [...m.get(site) || [], unit]);
    }, new Map()) || []
  ];


  if (user === null || unitsBySite.length === 0) {
    return (
      <Empty cms={cms.block('empty')} className="container mb-2">
        {new URLSearchParams(location.search).get('cancelled') !== null && (
          <cms.Markdown id="empty.cancelled" />
        )}
      </Empty>
    );
  }

  return (
    <>
      <SubNav ref={navRef}>
        <div className="container-xxl">
          <Link to="/sites" className="title text-decoration-none text-reset"><cms.Text id="locations" /></Link>
          {unitsBySite.map(([site, units]) => (
            <a href={`#${site.code}`} key={site.id} className={`text-decoration-none ${site.code === curSite ? 'text-primary' : 'text-muted'}`}>
              <strong>{getLangText(site.title)}</strong>
              <Parens className="ms-1">{units.length}</Parens>
            </a>
          ))}
        </div>
      </SubNav>

      <div className="container gx-0 gx-lg-2 pt-lg-4 pb-2 d-flex flex-column gap-5">
        {unitsBySite.map(([site, units]) => (
          <UnitsBySite
            key={site.id}
            setModal={setModal}
            site={site}
            units={units}
          />
        ))}
      </div>

      <Notification show={!!sd.order} onClose={() => setSearchData({})}>
        {sd.order && (
          <SuccessOrderBox
            cms={wurd.block('unitOrder.success')}
            btnId="myUnits"
            btnLink={`/account/units#order_${sd.order}`}
          />
        )}
      </Notification>

      <Modal id="customFieldsModal" show={!!modal?.content} size={modal?.size} onHide={() => setModal()} dialogAs={modal?.dialogAs}>
        <button type="button" className="btn text-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal()}><i className="text-white fal fa-times fa-2x" /></button>
        <div className="modal-body">
          {modal?.content}
        </div>
      </Modal>
    </>
  );
}
