import styled from '@emotion/styled';

export default styled.dl({
  display: 'grid',
  gridTemplateColumns: '3fr 1fr',
  gap: '.75rem 1rem',
  fontSize: '.875rem',

  '&>dt': {
    fontWeight: 400,
  },

  '&>dd': {
    textAlign: 'right',
  },

  '&>dd+dd': {
    gridColumn: '2 / 3',
  },

  '&:empty+hr': {
    display: 'none'
  },
});
