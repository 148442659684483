import { Link } from 'react-router-dom';


// extract patterns like invoice#sid from desc and render them as links
export default function InvoiceItemDescription({ children: desc }) {
  const matches = [...desc.matchAll(/(invoice)#([A-Z0-9]+)/g)];

  const newChildren = [desc.slice(0, matches[0]?.index)];

  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    newChildren.push(<Link to={`/account/${match[1]}s/${match[2]}`} onClick={e => e.stopPropagation()}>{match[2]}</Link>);
    newChildren.push(desc.slice(match.index + match[0].length, i < matches.length - 1 ? matches[i + 1].index : undefined));
  }

  return newChildren;
}