import wurd from 'wurd-react';
import pick from 'lodash/pick';
import { Link, useLocation } from 'react-router-dom';
import store from 'store';
import { Container } from './unit-progress';
import useScrollRef from 'utils/useScrollRef';


const cms = wurd.block('valetOrder.progress');

// const steps = [
//   'plan',
//   'boxes',
//   'products',
//   'area',
//   'time',
//   'billing',
//   'confirm',
// ];

export default function ValetProgress({ data, step }) {
  const scrollRef = useScrollRef('.active', [step]);
  const { settings, user } = store.get();
  const location = useLocation();
  const sp = Object.fromEntries(new URLSearchParams(location.search));
  const hasBoxes = ['time', 'area', 'billing', 'confirm'].includes(step);
  const hasDate = ['area', 'billing', 'confirm'].includes(step);
  const hasArea = ['billing', 'confirm'].includes(step);

  const getUrl = keys => `/valet?${Object.entries(pick(sp, keys)).map(([k,v]) => `${k}=${v}`).join('&')}`.replace(/\?$/, '');

  return (
    <Container ref={scrollRef}>
      <ol className="container-xxl">
        {settings.plans?.length > 0 && !user?.planId && (
          <li className={step === 'plan' ? 'active' : null}>
            {sp.plan
              ? (
                <Link to={getUrl(['market'])} className="text-decoration-none d-flex align-items-center gap-1">
                  <cms.Text id="plan" />
                </Link>
              ) : <cms.Text id="plan" />}
          </li>
        )}
        {settings.items?.length > 0 && (
          <li className={step === 'boxes' || step === 'products' ? 'active' : null}>
            {hasBoxes
              ? (
                <Link to={getUrl(['market', 'plan'])} className="text-decoration-none d-flex align-items-center gap-1">
                  <cms.Text id="items" />
                </Link>
              ) : <cms.Text id="items" />}
          </li>
        )}
        <li className={step === 'time' ? 'active' : null}>
          {hasDate
            ? (
              <Link to={getUrl(['market', 'plan', 'boxes', 'products'])} className="text-decoration-none d-flex align-items-center gap-1">
                <cms.Text id="date" />
              </Link>
            ) : <cms.Text id="date" />}
        </li>
        <li className={step === 'area' || step === 'billing' || step === 'confirm' ? 'active' : null}>
          {hasArea
            ? (
              <Link to={getUrl(['market', 'plan', 'boxes', 'products', 'date', 'timeslot', 'collect', 'collectDate', 'collectTimeslot'])} className="text-decoration-none d-flex align-items-center gap-1">
                <cms.Text id="user" />
              </Link>
            ) : <cms.Text id="user" />}
        </li>
      </ol>
    </Container>
  );
}
