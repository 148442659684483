import wurd from 'wurd-react';

import useForm from 'utils/useForm';

import Button from 'components/step-button';
import Field from 'components/field';
import Paper from 'components/paper';
import Block from 'components/block';
import Title from 'components/title';

const cms = wurd.block('unitOrder.prepay');
const prepayMonthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 18, 24, 36];


export default function UnitOrderPrepayStep({
  order,
  nextStep,
}) {
  const { fieldProps, formValue, isLoading, setErr } = useForm({
    initialValue: order,
    cms,
  });

  async function submit(e) {
    e.preventDefault();
    try {
      nextStep({
        prepayMonths: formValue.prepayMonths || 1,
      });
    } catch (err) {
      setErr(err);
    }
  }

  return (
    <Block as="form" onSubmit={submit}>
      <Title cms={cms.block('title')} className="mt-5" />

      <Paper padded className="mt-3">
        <Field {...fieldProps('prepayMonths')} wurdKeys="label,help,month,months">
          <select>{prepayMonthOptions.map(n => (
            <option key={n} value={n}>{n} {cms.text(`prepayMonths.${n > 1 ? 'months' : 'month'}`)}</option>
          ))}</select>
        </Field>
      </Paper>

      <Button type="submit" loading={isLoading}><cms.Text id="submitText" /></Button>
    </Block>
  )
}
