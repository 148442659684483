import store from '../../store';
import Field from 'components/field';
import { getLangText, sortByCode } from '../../utils/ui';
import CustomInput from './input';


export default function ({ fieldProps, fieldSettings, mode = 'create' /* 'create'|'update' */ }) {
  return fieldSettings.sort(sortByCode).map(({ code, title, ownerAcl = '', order, help, placeholder, ...rest }) => {
    if (mode === 'create' && !ownerAcl.includes('create')) return null; // only show it in profile if it has 'update' permission
    if (mode === 'update' && ownerAcl === 'read,create') return null;


    return (
      <Field
        key={code}
        readOnly={!ownerAcl.includes(mode)}
        {...fieldProps(`customFields.${code}`)}
        label={<label>{getLangText(title)}</label>}
        placeholder={placeholder}
        help={getLangText(help)}
      >
        <CustomInput {...rest} />
      </Field>
    );
  });
}
