import store from '../store';
import moment from 'moment';
import { typePrice } from './site';


export function getLangText(obj, lang = store.get('lang')) {
  if (!obj) return null;

  const { settings } = store.get();

  return obj[lang] || obj[settings?.languages?.[0]] || Object.values(obj).find(Boolean) || '';
}

export function getPrice(num) {
  const { settings, lang } = store.get();
  const symbol = settings.currency || '';

  // TODO: Use this when ISO currency code (3 letters) are available
  /*
  if (/^[A-Z]{3}$/.test(currencyCode)) {
    return new Intl.NumberFormat(language, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0
    }).format(num);
  }
  */

  // Some currencies such as Yen don't use decimals
  const fractionDigits = ['￥'].includes(symbol) ? 0 : 2;

  const formattedNum = Intl.NumberFormat(lang || [], {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(num);

  return symbol + formattedNum;
}

export function getUnitPrice({ rental, type }) {
  if (rental) return getPrice(rental.price);

  return getTypePrice(type);
}

export function getTypePrice(type) {
  return getPrice(typePrice(type));
}

export function getRent(invoice) {
  return invoice.entries.find(entry => {
    if (!entry.tags) return false;
    if (entry.tags.includes('rent') && !entry.tags.includes('prorated')) return entry;
  })?.amount || invoice.balance;
}

export function roundPrice(num, decimals = 2) {
  // See https://www.jacklmoore.com/notes/rounding-in-javascript/
  const roundedAbs = Number(Math.round(Math.abs(num) + 'e' + decimals) + 'e-' + decimals);
  if (Number.isNaN(roundedAbs)) {
    // rounded might be NaN when num was displayed in exponential notation, which happens if pointPos <= -6 or pointPos > 21 (https://2ality.com/2012/03/displaying-numbers.html 2.2)
    return Math.abs(num) < 1e-6 ? 0 : num; // return 0 for low numbers, else return num for large numbers
  }
  return Math.sign(num) * roundedAbs;
}


export function getDate(dateStr, format = 'short') {
  const { settings } = store.get();
  const pattern = settings?.dateFormats[format] || format;
  return moment(dateStr).format(pattern);
}


export function datePeriod(startDate, endDate, format = 'short') {
  const { settings, lang = 'en' } = store.get();
  const momentFormat = settings.dateFormats[format];

  const startMoment = moment(startDate).locale(lang);
  const result = [startMoment.format(momentFormat)];

  if (endDate) {
    const endMoment = moment(endDate).locale(lang);

    if (startDate !== endDate && endMoment.date() === startMoment.date()) {
      endMoment.add(-1, 'days');
    }

    result.push(endMoment.format(momentFormat));
  }

  return result;
}

const MI_TO_KM = 1.609344;

/**
 * @param {Number} distanceInMeters
 * @return {string}
 */
export function formatDistance(distanceInMeters) {
  const { settings, lang } = store.get();

  const distanceInUnit = (settings.distanceUnit === 'mi' ? 1 / MI_TO_KM : 1) * distanceInMeters / 1000;

  return Intl.NumberFormat(lang || [], {
    style: 'unit',
    unit: settings.distanceUnit === 'mi' ? 'mile' : 'kilometer',
    roundingMode: 'floor', // doesn't seem working yet in browsers&node, doesn't matter much for now
    useGrouping: false,
    maximumFractionDigits: distanceInUnit < 1 ? 3 : 0,
  }).format(distanceInUnit);
}

export const sortByCode = (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity) || a.code?.localeCompare(b.code);
