import wurd from 'wurd-react';
import Title from 'components/title';
import Block from 'components/block';
import Button from 'components/button';
import { getIdentityUrl } from 'plugins/stripe/actions';

const cms = wurd.block('unitOrder.identity');

export default function UnitOrderBillingStep({ nextStep, order }) {
  return (
    <Block>
      <Title cms={cms.block('title')} className="mt-5" />

      <p className="mt-4"><cms.Text id="subtitle"/></p>

      <form action={getIdentityUrl()} method="POST">
        <Button type="submit" className="mt-4">
          <cms.Text id="submitBtn" />
        </Button>
      </form>
    </Block>
  )
}
