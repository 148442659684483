import { cloneElement, forwardRef, Fragment } from 'react';
import wurd, { WurdText, WurdObject } from 'wurd-react';
import { getErrMsg } from './err-msg';
import Markdown from './markdown';


export default forwardRef(
  ({
    cms,
    as: As = 'div',
    children = <input />,
    label = <label />,
    help,
    error, // undefined|string|ReactNode
    wurdKeys = 'label,help,placeholder',
    isLoading,
    ...props
  }, ref) => {
    const type = children.props.type || props.type;
    const isCheck = type === 'checkbox' || type === 'boolean';

    const input = cloneElement(children, {
      id: cms.id(),
      ...props,
      placeholder: props.placeholder || cms.text('placeholder') || ' ', // .form-floating need non empty placeholder else the label will be in already in focused minimized state
      className: `${Array.isArray(props.value) ? 'form-select' : isCheck ? 'form-check-input' : 'form-control'} ${error ? 'is-invalid' : ''} ${children.props.className || ''}`,
      ref,
    });

    const labelElement = cloneElement(label,
      {
        htmlFor: cms.id(),
        ...isCheck && { className: 'form-check-label' }
      },
      label.props.children || cms.text('label') || props.name,
      isLoading && <i className="far fa-spinner fa-spin ms-1" />,
    );
    const helpElement = help
      ? <Markdown inline as="small" className="text-muted">{help}</Markdown>
      : <cms.Text type="small" id="help" markdown className="text-muted" />;
    const errMsg = typeof error === 'string' ? getErrMsg({ message: error }) : error; // TODO: This doesn't appear to be working

    return (
      <WurdObject id={cms.id()} keys={wurdKeys} type={As}>
        <div className={isCheck ? 'form-check mt-4' : 'form-floating'}>
          {input}
          {labelElement}
        </div>
        {helpElement}
        {errMsg && <div className="invalid-feedback d-block">{errMsg}</div>}
      </WurdObject>
    );
  }
);
