import styled from '@emotion/styled';
import Paper from 'components/paper';


const PaperWrapper = styled(Paper)({
  fontSize: 14,

  '.sgc-image > img': {
    borderRadius: 2,
    //minWidth: 130,
    width: '100%',
    height: '100%',
    maxHeight: 180,
    aspectRatio: '4 / 3',
    objectFit: 'contain',
  },
});


export default function ListItemCard({
  image,
  imageFit, // 'contain'|'cover'
  imageAlt = '',
  children,
  padded = false,
  className = '',
  as,
}) {
  return (
    <PaperWrapper
      className={`p-2 d-sm-flex ${className}`}
      padded={padded}
      as={as}
    >
      <div className="sgc-image flex-1 flex-shrink-0">
        <img src={image || 'https://via.placeholder.com/150'} alt={imageAlt} className={imageFit} />
      </div>

      <div className="sgc-main flex-2 d-flex flex-column mt-2 mt-sm-0 ms-sm-2 mw-0">
        {children}
      </div>
    </PaperWrapper>
  );
}