import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import wurd from 'wurd-react';

import store from 'store';
import * as actions from 'actions';

import Button from 'components/button';
import DatePickerField from 'components/datepicker/field';
import Title from 'components/title';
import analytics from 'utils/analytics';
import { /* getUnitTypes, */ typePrice } from 'utils/site';
import { getLangText } from 'utils/ui';


const cms = wurd.block('unitDetail');

export default function UnitTypeDetail_DatePicker({
  site,
  unitType,
}) {
  const { settings } = store.get();
  const navigate = useNavigate();
  const [date, setDate] = useState(undefined);

  async function selectDate() {
    const { unitOrder: order } = store.get();
    if (order) {
      await actions.unitOrders.updateCurrent({ siteId: site.id, unitTypeId: unitType.id }, { startDate: date });
    }

    analytics.selectItem({
      item_list_name: 'Unit type',
      items: [{
        item_id: unitType.id,
        item_name: getLangText(unitType.title, ''),
        item_brand: getLangText(site.title, ''),
        price: typePrice(unitType),
        //index: getUnitTypes(site).findIndex(u => u.id === unitType.id),
      }]
    });
    navigate(`/sites/${site.code}/${unitType.code}/${date}`);
  }

  return (
    <>
      <Title cms={cms.block('date.title')} className="px-5" />

      <cms.Markdown id="date.info" type="div" className="my-4 px-5 text-muted lh-lg" />

      <DatePickerField
        show
        name="date"
        value={date}
        dateFormat={settings.dateFormats.short}
        onChange={e => setDate(e.target.value)}
        min={site.minmaxBookingDates?.[0] || moment().format('YYYY-MM-DD')}
        max={site.minmaxBookingDates?.[1]}
        excludedDates={site.excludedBookingDates}
        cms={cms.block('date')}
      />

      <Button className="mt-3 d-block m-auto" disabled={!date} onClick={selectDate}>
        <cms.Text id="submitText" />
      </Button>
    </>
  );
}
