/**
 * A 'Load more' button used for InfiniteQuery pagination.
 */
import { WurdText } from 'wurd-react';


export default function ListLoadMoreButton({
  infiniteQuery,
}) {
  const {
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = infiniteQuery;

  return (
    <>
      {hasNextPage && (
        <button
          type="button"
          className="btn btn-link text-decoration-none p-2"
          onClick={fetchNextPage}
        >
          {isFetchingNextPage
            ? <i className="fa fa-spinner fa-spin me-1" />
            : <i className="fa fa-chevron-double-down me-1" />
          }
          <WurdText id="common.loadMore"/>
        </button>
      )}
    </>
  );
}
