import { useRef, useEffect } from 'react';


export default function useScrollRef(selector, deps) {
  const ref = useRef();
  useEffect(() => {
    window.requestAnimationFrame(() => {
      const target = ref.current?.querySelector(selector);
      if (!target) return;

      // check if in view (horizontally), we don't use scrollIntoView because it also scroll up the page if the progress is not visible
      const deltaX = ref.current.scrollLeft + ref.current.offsetWidth - (target.offsetLeft + target.offsetWidth);

      if (deltaX < 0) ref.current.scrollBy(-deltaX, 0);
    });
  }, [ref.current, ...deps || []]);

  return ref;
}