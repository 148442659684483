import { createContext, useEffect, useState, useContext } from 'react';
import wurd from 'wurd-react';
import Fade from 'react-bootstrap/Fade';
import Paper from 'components/paper';


export const NotifyContext = createContext();


export function NotifyProvider({ children, timeout = 7500 }) {
  const [content, notify] = useState();
  const hide = () => notify(null);

  useEffect(() => {
    if (!content) return;
    const t = setTimeout(hide, timeout);
    return () => clearTimeout(t);
  }, [!!content]);

  return (
    <NotifyContext.Provider value={notify}>
      {children}

      <Fade in={!!content}>
        <div className="position-absolute start-50 translate-middle-x" style={{ zIndex: content ? 1031 : -1, bottom: 40, minWidth: 250 }}>
          {content && (
            <Paper className="fade shadow-lg show d-flex justify-content-between">
              <div className="py-2 px-4 d-flex align-items-center">
                <i className="fas fa-check text-primary fs-4 me-2" /> {content}
              </div>
              <button type="button" className="btn btn-sm text-primary" aria-label="Close" onClick={hide}>
                <i className="fal fa-times fa-2x" />
              </button>
            </Paper>
          )}
        </div>
      </Fade>
    </NotifyContext.Provider>
  );
}

export default function () {
  const notify = useContext(NotifyContext);

  return notify;
}